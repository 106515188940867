import { useEffect } from "react";
import { Timeline } from "flowbite-react";
import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import workExp from "../../data/work_exp";

gsap.registerPlugin(ScrollTrigger);

const TimelineComponent = ({ isMobile }) => {
  useEffect(() => {
    workExp.forEach((exp) => {
      const parent = exp.ref.current;
      const item = parent.querySelector(".tl_item");

      if (!isMobile) {
        ScrollTrigger.create({
          trigger: item,
          start: "top center",
          end: "bottom center",
          scrub: true,
          toggleActions: "play reverse play reverse",
          onToggle: (self) => {
            if (self.isActive) {
              item.classList.add("active");

              // Move marker to project location.

              gsap.to("#marker", {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                  gsap.set("#marker", {
                    left: exp.pos.x,
                    top: exp.pos.y,
                  });
                },
              });

              if (exp.hidden) {
                return;
              }

              gsap.to("#marker", {
                opacity: 1,
                duration: 0.3,
                delay: 0.5,
              });
            } else {
              item.classList.remove("active");
            }
          },
        });
      }
    });
  }, [isMobile]);

  return (
    <div className="h-full">
      <Timeline className="border-black border-none tl">
        {workExp.map((exp, idx) => (
          <div ref={exp.ref} key={idx} className="it_wrapper">
            <Timeline.Item id={`exp-${idx}`} className="tl_item">
              <Timeline.Point className="timeline-point" />
              <Timeline.Content className="pb-14 flex flex-col justify-start">
                <Timeline.Time>
                  <span className="caption-small inline-block mb-4">
                    {exp.timeframe}
                  </span>
                </Timeline.Time>
                <Timeline.Title className="title-small">
                  {exp.title.toUpperCase()}
                </Timeline.Title>
                <Timeline.Body>
                  <p className="body-base text-[#93857D] mt-2">
                    {exp.institution}
                  </p>

                  {isMobile && <p className="text-xs mt-2">{exp.location}</p>}
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
          </div>
        ))}
      </Timeline>
    </div>
  );
};

export default TimelineComponent;
