import { createRef } from "react";

const workExp = [
  {
    title: "B.SC ARCHITECTURE ENGINEERING",
    institution: "University of Sharjah",
    timeframe: "2008-2013",
    location: "Dubai, United Arab Emirates",
    ref: createRef(),
    pos: { x: 510, y: 110 },
  },
  {
    title: "Speaker",
    institution: "TEDx",
    timeframe: "2013",
    location: "Dubai, United Arab Emirates",
    ref: createRef(),
    pos: { x: 510, y: 110 },
  },
  {
    title: "JUNIOR ARCHITECT",
    institution: "AE7 Architectural Design Consultant",
    timeframe: "2013-2015",
    location: "Dubai, United Arab Emirates",
    ref: createRef(),
    pos: { x: 510, y: 110 },
  },
  {
    title: "Everest Base Camp treck",
    institution: "Adventure",
    timeframe: "2014",
    location: "Himalayas, Nepal",
    ref: createRef(),
    pos: { x: 600, y: 90 },
  },
  {
    title: "ARCHITECT",
    institution: "Killa Design",
    timeframe: "2015-2017",
    location: "Dubai, United Arab Emirates",
    ref: createRef(),
    pos: { x: 510, y: 110 },
  },
  {
    title: "Scuba Diving",
    institution: "Adventure",
    location: "Bali, Indonesia",
    timeframe: "2016",
    ref: createRef(),
    pos: { x: 640, y: 150 },
  },
  {
    title: "Rock Climbing",
    institution: "Adventure",
    location: "Strasbourg, France",
    timeframe: "2017",
    ref: createRef(),
    pos: { x: 350, y: 70 },
  },
  {
    title: "M.SC SPACE STUDIES",
    institution: "International Space University",
    location: "Strasbourg, France",
    timeframe: "2017-2018",
    ref: createRef(),
    pos: { x: 350, y: 70 },
  },
  {
    title: "Consulting Judge",
    institution: "Google X-prize | Robotics",
    location: "Los Angeles, United States",
    timeframe: "2017",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "Research scholar in Robotics design",
    institution: "Space Robotics Laboratories - Tohoku University",
    timeframe: "2018",
    location: "Sendai, Japan",
    ref: createRef(),
    pos: { x: 680, y: 73 },
  },
  {
    title: "Co-founder | CEO",
    institution: "Space Roasters Inc.",
    timeframe: "2018-2020",
    location: "San Diego, United States",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "LEAD ARCHITECHT",
    institution: "Killa Design",
    location: "Dubai, United Arab Emirates",
    timeframe: "2018-2020",
    ref: createRef(),
    pos: { x: 510, y: 110 },
  },
  {
    title: "Pilot license",
    institution: "Astronaut Training",
    location: "Toronto, Canada",
    timeframe: "2019",
    ref: createRef(),
    pos: { x: -25, y: 40 },
  },
  {
    title: "Presenter of Lunar Habitate Design",
    institution: "NASA JSC",
    timeframe: "2020",
    location: "Houston, United States",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "Head of Product & Revenue",
    institution: "RA Sanctuaries",
    location: "Toronto, Canada",
    timeframe: "2021-2022",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "Patent holder",
    institution: "Space Roasters Inc.",
    location: "San Diego, United States",
    timeframe: "2021",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "Skydiving license",
    institution: "Astronaut Training",
    location: "Sevilla, Spain",
    timeframe: "2022",
    ref: createRef(),
    pos: { x: 320, y: 83 },
  },
  {
    title: "CTO",
    institution: "RA Sanctuaries",
    timeframe: "2022-2023",
    location: "Austin, United States",
    ref: createRef(),
    pos: { x: 40, y: 83 },
  },
  {
    title: "Co-founder",
    institution: "Stealth mode",
    timeframe: "2023",
    location: "The World",
    ref: createRef(),
    pos: { x: 120, y: 93 },
    hidden: true
  },
];

export default workExp;
