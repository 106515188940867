import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import CustomEase from "gsap/CustomEase";
import { Link } from "react-router-dom";

gsap.registerPlugin(CustomEase);
const Article = ({ data, lg, animate, idx, top = false }) => {
  const ref = useRef();

  useEffect(() => {
    CustomEase.create("news", "0.33, 0.00, 0.20, 1.00");
    if (animate) {
      if (top) {
        gsap.from(ref.current, {
          autoAlpha: 0,
          y: 100,
          ease: "news",
          delay: 0.2 * (idx - 1),
          duration: 2,
        });
      } else {
        gsap.from(ref.current, {
          autoAlpha: 0,
          y: 100,
          ease: "news",
          delay: 0.2 * (idx - 1),
          duration: 2,
          scrollTrigger: {
            trigger: ref.current, 

          },
        });
      }
    }
  }, [animate, idx, top]);

  useEffect(() => {
    const blurryDivs = document.querySelectorAll(".blur-bg");
    blurryDivs.forEach((div) => {
      const img = div.querySelector("img");

      function loaded() {
        div.classList.add("loaded");
      }

      if (img.complete) {
        loaded();
      } else {
        img.addEventListener("load", loaded);
      }
    });
  }, []);

  const handleClick = () => {
    window.open(data.url, "_blank");
  };

  return (
    <div className="mb-20" ref={ref}>
      <div
        className="blur-bg rounded-lg cursor-pointer transition hover:opacity-[.7]"
        style={{ backgroundImage: `url(${data.image_sm})` }}
        onClick={handleClick}
      >
        <img
          src={data.image}
          alt={data.alt || data.title}
          loading="lazy"
          className={`block mb-3 rounded-lg w-full object-cover ${
            lg ? "h-auto" : " h-[230px]"
          }`}
        />
      </div>
      <div className="absolute">
        <p className="caption-small text-[#BF7E58] mb-3">{data.reporter}</p>
        <Link
          to={data.url}
          target="_blank"
          rel="noreferrer"
          title={data?.title}
          className="text-[#22140C] title-medium capitalize hover:opacity-60"
        >
          {lg ? (
            <>
              {data.title.length > 100
                ? data.title.substring(0, 100) + "..."
                : data.title}
            </>
          ) : (
            <>
              {data.title.length > 50
                ? data.title.substring(0, 50) + "..."
                : data.title}
            </>
          )}
        </Link>
      </div>
    </div>
  );
};

export default Article;
