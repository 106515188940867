import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as DownIcon } from "../../assets/icons/down-chevron.svg";
import { setPageHeader } from "../../redux/projects/projects.slice";
import Menu from "../Menu/Menu";
import gsap from "gsap";

const Navbar = ({ fixed, titleRef, light, className = "" }) => {
  const isMobile = window.innerWidth <= 1030;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef();
  const nameRef = useRef();
  const tlRef = useRef();
  const arrowRef = useRef();
  const [isHomePage, setisHomePage] = useState(false);
  const [menuState, setMenuState] = useState(0); // 0 - closed, 1 - open
  const { pageHeader } = useSelector((state) => state.projects);

  useEffect(() => {
    setisHomePage(location.pathname === "/");
  }, [location.pathname]);

  // useEffect(() => {
  //   const tl = gsap.timeline({
  //     paused: true,
  //     onComplete: () => setMenuState(1),
  //   });

  //   tl.to(
  //     nameRef.current,
  //     {
  //       duration: 0.5,
  //       y: -20,
  //       autoAlpha: 0,
  //       ease: "power3.inOut",
  //     },
  //     "start"
  //   );

  //   tl.to(
  //     arrowRef.current,
  //     {
  //       rotate: -180,
  //       duration: 0.4,
  //       ease: "slow",
  //     },
  //     "start"
  //   );

  //   tl.to(
  //     "#island",
  //     {
  //       width: "320px",
  //       duration: 0.5,
  //       delay: 0.1,
  //     },
  //     "start"
  //   );

  //   tl.from(
  //     menuRef.current,
  //     {
  //       y: 13,
  //       autoAlpha: 0,
  //       duration: 0.5,
  //       delay: 0.15,
  //       ease: "power1.out",
  //     },
  //     "start"
  //   );

  //   tlRef.current = tl;
  // }, []);

  useEffect(() => {
    // Set opacity based on scroll position
    if (!isMobile) {
      document.addEventListener("scroll", (e) => {
        const scrollPos = window.scrollY;
        if (scrollPos <= 100) {
          const pct = scrollPos / 500;
          document.getElementById(
            "island"
          ).style.backgroundColor = `rgba(0,0,0,${pct.toFixed(2)})`;
        }
      });
    }
  }, []);

  // const onTitleHover = (e) => {
  //   if (menuState === 0) {
  //     tlRef.current.play();
  //   }
  // };

  // const onTitleMouseLeave = (e) => {
  //   tlRef.current.reverse();
  //   setMenuState(0);
  // };

  const onNavigate = (route) => {
    switch (route) {
      case "/about":
        dispatch(setPageHeader("ABOUT"));
        break;
      case "/works":
        dispatch(setPageHeader("WORKS"));
        break;
      case "/contact":
        dispatch(setPageHeader("CONTACT"));
        break;
      case "/news":
        dispatch(setPageHeader("NEWS"));
        break;
      default:
        dispatch(setPageHeader("HATEM HUSSAIN"));
        break;
    }

    navigate(route);
  };

  return (
    <div
      id="navbar"
      className={
        fixed ? `fixed z-10 w-full ${className}` : `${className} w-full z-30`
      }
    >
      <div
        className={`w-full top-0 flex items-start ${
          isMobile ? "justify-between" : "justify-center"
        } px-5 md:px-14 py-5 md:pt-8 pb-5 `}
      >
        {isMobile ? (
          <>
            <Link
              ref={nameRef}
              to="/"
              className={`hover:opacity-80 block text-xs tracking-distant font-medium ${
                light ? "text-[#22140C] hover:opacity-50" : ""
              }`}
            >
              {pageHeader}
            </Link>

            <Menu light={light} />
          </>
        ) : (
          // <div
          //   className={`flex relative flex-col items-center text-center  ${
          //     isHomePage && "invisible"
          //   }`}
          //   ref={titleRef}
          //   onMouseEnter={onTitleHover}
          //   onMouseLeave={onTitleMouseLeave}
          // >
          //   <div
          //     id="island"
          //     className={`relative rounded-full bg-[rgba(0,0,0,0)] backdrop-blur-sm px-4 h-[35px] flex justify-center items-center ${
          //       pageHeader === "HATEM HUSSAIN" ? "w-[200px]" : "w-fit"
          //     }`}
          //   >
          //     <p
          //       ref={nameRef}
          //       className={`hover:opacity-80 block caption-base ${
          //         light ? "text-[#22140C] hover:opacity-50" : ""
          //       }`}
          //     >
          //       {pageHeader}
          //     </p>

          //     <div
          //       ref={menuRef}
          //       className="space-x-6 absolute w-[400px] invisible"
          //     >
          //       <button
          //         onClick={() => onNavigate("/")}
          //         className={`text-[12px] font-extralight hover:text-primary ${
          //           light ? "text-[#22140C]" : ""
          //         }`}
          //         to="/"
          //       >
          //         Home
          //       </button>
          //       {/* <button
          //         onClick={() => onNavigate("/works")}
          //         className={`text-[12px] font-extralight hover:text-primary ${
          //           light ? "text-[#22140C]" : ""
          //         }`}
          //         to="/works"
          //       >
          //         Works
          //       </button> */}
          //       <button
          //         onClick={() => onNavigate("/about")}
          //         className={`text-[12px] font-extralight hover:text-primary ${
          //           light ? "text-[#22140C]" : ""
          //         }`}
          //         to="/about"
          //       >
          //         About
          //       </button>
          //       <button
          //         onClick={() => onNavigate("/news")}
          //         className={`text-[12px] font-extralight hover:text-primary ${
          //           light ? "text-[#22140C]" : ""
          //         }`}
          //         to="/news"
          //       >
          //         News
          //       </button>
          //       <button
          //         onClick={() => onNavigate("/contact")}
          //         className={`text-[12px] font-extralight hover:text-primary ${
          //           light ? "text-[#22140C]" : ""
          //         }`}
          //         to="/contact"
          //       >
          //         Contact
          //       </button>
          //     </div>
          //   </div>

          //   <div>
          //     <DownIcon
          //       ref={arrowRef}
          //       className={`mt-2 ${light ? "text-[#22140C]" : "text-[#ddd]"}`}
          //     />
          //   </div>
          // </div>

          <div id="island" className="space-x-6 text-center absolute w-fit px-2 rounded-lg">
            <button
              onClick={() => onNavigate("/")}
              className={`text-[12px] font-extralight hover:text-primary ${
                light ? "text-[#22140C]" : ""
              }`}
              to="/"
            >
              Home
            </button>
            {/* <button
                  onClick={() => onNavigate("/works")}
                  className={`text-[12px] font-extralight hover:text-primary ${
                    light ? "text-[#22140C]" : ""
                  }`}
                  to="/works"
                >
                  Works
                </button> */}
            <button
              onClick={() => onNavigate("/about")}
              className={`text-[12px] font-extralight hover:text-primary ${
                light ? "text-[#22140C]" : ""
              }`}
              to="/about"
            >
              About
            </button>
            <button
              onClick={() => onNavigate("/news")}
              className={`text-[12px] font-extralight hover:text-primary ${
                light ? "text-[#22140C]" : ""
              }`}
              to="/news"
            >
              News
            </button>
            <button
              onClick={() => onNavigate("/contact")}
              className={`text-[12px] font-extralight hover:text-primary ${
                light ? "text-[#22140C]" : ""
              }`}
              to="/contact"
            >
              Contact
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
