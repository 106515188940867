import ArticleImg1 from "../assets/articles/news-img1.webp";
import ArticleImg1_Small from "../assets/articles/news-img1-small.webp";
import ArticleImg2 from "../assets/articles/news-img2.webp";
import ArticleImg2_Small from "../assets/articles/news-img2-small.webp";
import ArticleImg3 from "../assets/articles/news-img3.webp";
import ArticleImg3_Small from "../assets/articles/news-img3-small.webp";
import ArticleImg4 from "../assets/articles/news-img4.webp";
import ArticleImg4_Small from "../assets/articles/news-img4-small.webp";
import ArticleImg5 from "../assets/articles/news-img5.webp";
import ArticleImg5_Small from "../assets/articles/news-img5-small.webp";
import ArticleImg6 from "../assets/articles/news-img6.webp";
import ArticleImg6_Small from "../assets/articles/news-img6-small.webp";
import ArticleImg7 from "../assets/articles/news-img7.webp";
import ArticleImg7_Small from "../assets/articles/news-img7-small.webp";
import ArticleImg8 from "../assets/articles/news-img8.webp";
import ArticleImg8_Small from "../assets/articles/news-img8-small.webp";
import ArticleImg9 from "../assets/articles/news-img9.webp";
import ArticleImg9_Small from "../assets/articles/news-img9-small.webp";

const newsData = [
  {
    index: 0,
    title: "Would you pay Dh1,500 for a cup of coffee roasted in space?",
    image: ArticleImg1,
    image_sm: ArticleImg1_Small,
    reporter: "The National",
    url: "https://www.thenationalnews.com/lifestyle/food/would-you-pay-dh1-500-for-a-cup-of-coffee-roasted-in-space-1.826901",
  },
  {
    index: 1,
    title: "Out of this world startup plans to roast coffee in space",
    image: ArticleImg2,
    image_sm: ArticleImg2_Small,
    reporter: "Global Coffee Report",
    url: "https://www.gcrmag.com/out-of-this-world-startup-plans-to-roast-coffee-in-space/",
  },
  {
    index: 2,
    title: "Coffee roasting in space",
    image: ArticleImg3,
    image_sm: ArticleImg3_Small,
    reporter: "Room",
    url: "https://room.eu.com/article/coffee-roasting-in-space",
  },
  {
    index: 3,
    title: "OFFICE OF THE FUTURE",
    image: ArticleImg4,
    image_sm: ArticleImg4_Small,
    reporter: "Architecture Masterprize",
    url: "https://architectureprize.com/winners/winner.php?id=3180",
  },
  {
    index: 4,
    title: "Coffee beans shot into space, roasted for a Dubai coffee shop",
    image: ArticleImg5,
    image_sm: ArticleImg5_Small,
    reporter: "UAEBARQ",
    url: "https://www.uaebarq.ae/en/2019/02/03/coffee-beans-shot-into-space-roasted-for-a-dubai-coffee-shop/",
  },
  {
    index: 5,
    title:
      "International Space University alums Hatem Alkhafaji and Anders Cavallini have founded Space Roasters, to explore how space science and technology can improve coffee roasting in space.",
    image: ArticleImg6,
    image_sm: ArticleImg6_Small,
    reporter: "Collect Space",
    url: "http://www.collectspace.com/ubb/Forum3/HTML/005333.html",
  },
  {
    index: 6,
    title: "Hitting ‘print’ on the office of the future",
    image: ArticleImg7,
    image_sm: ArticleImg7_Small,
    reporter: "WeWork",
    url: "https://www.wework.com/ideas/community-stories/member-spotlight/hitting-print-on-the-office-of-the-future",
  },
  {
    index: 7,
    title: "Office of the Future / Killa Design",
    image: ArticleImg8,
    image_sm: ArticleImg8_Small,
    reporter: "Arch Daily",
    url: "https://www.archdaily.com/875642/office-of-the-future-killa-design",
  },
  {
    index: 9,
    title: 'طالب بجامعة الشارقة يصدر أول كتيّب لـ "العمارة" في القمر',
    image: ArticleImg9,
    image_sm: ArticleImg9_Small,
    reporter: "الرئيسية",
    url: "https://www.alkhaleej.ae/2012-09-13/%D8%B7%D8%A7%D9%84%D8%A8-%D8%A8%D8%AC%D8%A7%D9%85%D8%B9%D8%A9-%D8%A7%D9%84%D8%B4%D8%A7%D8%B1%D9%82%D8%A9-%D9%8A%D8%B5%D8%AF%D8%B1-%D8%A3%D9%88%D9%84-%D9%83%D8%AA%D9%8A%D9%91%D8%A8-%D9%84%D9%80-%D8%A7%D9%84%D8%B9%D9%85%D8%A7%D8%B1%D8%A9-%D9%81%D9%8A-%D8%A7%D9%84%D9%82%D9%85%D8%B1/%D9%85%D8%AD%D8%B7%D8%A7%D8%AA",
  },
];

export default newsData;
