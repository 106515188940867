import { useEffect } from "react";
import Lenis from "lenis";
import Navbar from "../../components/Navbar/Navbar";
import Article from "../../components/Article/Article";
import articles from "../../data/articles";

const News = () => {
  const isMobile = window.innerWidth <= 1030;
  const [, , ...gridItems] = articles;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => t * (2 - t),
      smooth: true,
      direction: "vertical", // can be 'vertical' or 'horizontal'
      gestureDirection: "vertical", // can be 'vertical' or 'horizontal'
      smoothTouch: false,
      touchMultiplier: 2,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <div id="news" className="bg-[#fff] min-h-screen">
      <Navbar light fixed={isMobile} className="bg-white md:bg-transparent" />

      <div id="showcase" className="max-w-6xl mx-auto pt-24 md:pt-16 px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 md:grid-row-2 md:gap-5 gap-y-20 items-end">
          <div className="col-span-2 row-span-2">
            <Article data={articles[0]} idx={0} animate lg top />
          </div>
          <div></div>
          <div>
            <Article data={articles[1]} idx={1} animate top />
          </div>
        </div>
      </div>
      <div
        id="content"
        className="grid grid-cols-1 md:grid-cols-3 md:gap-x-5 gap-y-20 items-stretch max-w-6xl mx-auto md:pt-24 pt-20 pb-32 px-4"
      >
        {gridItems.map((item, idx) => (
          <Article key={item.index} idx={idx} data={item} animate />
        ))}
      </div>
    </div>
  );
};

export default News;
