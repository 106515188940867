import {useEffect, useRef } from "react";
import { useCountUp } from "react-countup";

const Counter = ({ num }) => {
  const countUpRef = useRef(null);
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: num,
    duration: 1,
  });

  useEffect(() => {
    update(num)
  }, [num, update]);

  return (
    <>
      <p className="body-xl mb-3" ref={countUpRef}>
      </p>
    </>
  );
};

export default Counter;
