import React, { createRef, Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import About from "./pages/About/About";
import Project from "./pages/Project/Project";
import Works from "./pages/Works/Works";
import Contact from "./pages/Contact/Contact";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import store from "./redux/store";
import "./styles/main.css";

const routes = [
  {
    path: "/projects/:projectID",
    name: "Project",
    element: <Project />,
    nodeRef: createRef(),
  },
  {
    path: "/news",
    name: "News",
    element: <News />,
    nodeRef: createRef(),
  },
  {
    path: "/about",
    name: "About",
    element: <About />,
    nodeRef: createRef(),
  },
  {
    path: "/works",
    name: "Works",
    element: <Works />,
    nodeRef: createRef(),
  },
  {
    path: "/contact",
    name: "Contact",
    element: <Contact />,
    nodeRef: createRef(),
  },
  {
    path: "*",
    name: "404",
    element: <PageNotFound />,
    nodeRef: createRef(),
  },
  { path: "/", name: "Home", element: <Home />, nodeRef: createRef() },
];

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<></>}>
      <RouterProvider router={router} />
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
