import { useEffect, useState } from "react";
import Lenis from "lenis";

export const useSmoothScroll = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    // Only initialize Lenis if it's a desktop device
    if (isDesktop) {
      const lenis = new Lenis({
        duration: 1.2,
        easing: (t) => t * (2 - t),
        smooth: true,
        direction: "vertical",
        gestureDirection: "vertical",
        wheelMultiplier: 1.2,
        lerp: 0.1,
      });

      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }

      requestAnimationFrame(raf);

      // Optimize for window resize
      const resizeObserver = new ResizeObserver(() => lenis.resize());
      resizeObserver.observe(document.body);

      return () => {
        lenis.destroy();
        resizeObserver.disconnect();
        window.removeEventListener('resize', handleResize);
      };
    }

    // Cleanup function for non-desktop devices
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isDesktop]);
};

// duration: 1.5, // Reduced from 1.2 for faster scrolling
// easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Improved easing function
