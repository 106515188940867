import { useRef, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";
import { gsap } from "gsap";
import { projects } from "../../data/projects";
import { useSmoothScroll } from "../../hooks/useSmoothScroll";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as UpIcon } from "../../assets/icons/up.svg";
import TextTransition, { presets } from "react-text-transition";
import Navbar from "../../components/Navbar/Navbar";
import Timeline from "../../components/Timeline/Timeline";
import SplitType from "split-type";
import personalImages from "../../data/personal";
import "./About.css";

const TEXTS = [
  "Astronaut to be",
  "Space Architect",
  "Entrepreneur",
  "Adventurer",
];
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const About = () => {
  useSmoothScroll();
  const bgRef = useRef();
  const progressRef = useRef();
  const [pageState, setPageState] = useState(0);
  const [imageArr, setImageArr] = useState(personalImages[pageState]);
  const [personaProjects, setPersonaProjects] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1030);
  const [heroData] = useState([
    {
      path: "/works?search=space",
      name: "astronaut",
      stats: [],
      persona: "astronaut",
    },
    {
      path: "/works?search=architecture",
      name: "architecture",
      stats: [],
      persona: "architect",
    },
    {
      path: "/works?search=entrepreneurship",
      name: "entrepreneurship",
      stats: [],
      persona: "entrepreneur",
    },
    {
      path: "/works?search=adventurer",
      name: "adventurer",
      stats: [],
      persona: "adventurer",
    },
  ]);

  const updatePageState = useCallback(() => {
    setPageState((prevState) => (prevState + 1) % 4);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const text = new SplitType("#text1", { types: "words, lines" });
    const text2 = new SplitType("#text2", { types: "words, lines" });

    gsap.from(text.lines, {
      opacity: 0,
      y: 100,
      duration: 0.8,
      stagger: { amount: 0.4 },
      scrollTrigger: {
        trigger: "#text1",
      },
    });

    gsap.from(text2.lines, {
      opacity: 0,
      y: 100,
      duration: 0.8,
      stagger: { amount: 0.5 },
      delay: 0.5,
      scrollTrigger: {
        trigger: "#text2",
      },
    });

    // Cleanup timeouts
    return () => {
      // Cleanup all settimeouts
      const highestId = window.setTimeout(() => {
        for (let i = highestId; i >= 0; i--) {
          window.clearTimeout(i);
        }
      }, 0);
    };
  }, []);

  useEffect(() => {
    setImageArr(personalImages[pageState]);

    const projectsForPersona = projects.filter(
      (proj) =>
        proj.persona.toLowerCase() === heroData[pageState].persona.toLowerCase()
    );

    setPersonaProjects(projectsForPersona);
  }, [pageState, heroData]);

  useEffect(() => {
    window.clearTimeout();

    for (let i = 0; i < imageArr.length; i++) {
      setTimeout(() => {
        const imgTag = new Image();
        imgTag.onload = () => {
          if (bgRef.current) {
            bgRef.current.style.backgroundImage = isMobile
              ? `linear-gradient(to bottom, transparent 70%, #fff),
              url(${imageArr[i]})`
              : `linear-gradient(to left, transparent 40%, #fff),
            url(${imageArr[i]})`;
          }
        };

        imgTag.src = imageArr[i];
      }, 100 * i);
    }

    if (progressRef.current) {
      gsap.fromTo(
        progressRef.current,
        {
          width: 0,
        },
        {
          width: "100%",
          duration: 5,
          delay: 2,
          ease: "slow",
        }
      );
    }
  }, [imageArr, isMobile]);

  useEffect(() => {
    let intervalId;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(intervalId);
      } else {
        intervalId = setInterval(updatePageState, 7000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    intervalId = setInterval(updatePageState, 7000);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [updatePageState]);

  useEffect(() => {
    const toggleShowBtn = () => {
      if (!isMobile) {
        if (window.scrollY > 400) {
          gsap.to("#backToTop", {
            autoAlpha: 1,
          });
        } else {
          gsap.to("#backToTop", {
            autoAlpha: 0,
          });
        }
      } else {
        if (window.scrollY > 150) {
          gsap.to("#navbar", {
            background: "#fff",
            duration: 0.5,
          });
        } else {
          gsap.to("#navbar", {
            background: "transparent",
            duration: 0.5,
          });
        }
      }
    };

    window.addEventListener("scroll", toggleShowBtn);
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1030);
    });

    return () => {
      window.removeEventListener("scroll", toggleShowBtn);
      window.removeEventListener("resize", () => {
        setIsMobile(window.innerWidth < 1030);
      });
    };
  }, [isMobile]);

  const onNextClick = () => {
    clearInterval();

    if (pageState === 3) {
      setPageState(0);
    } else {
      setPageState(pageState + 1);
    }
  };

  const onPrevClick = async () => {
    if (pageState === 0) {
      setPageState(3);
    } else {
      setPageState(pageState - 1);
    }
  };

  const goToTop = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: 20,
    });
  };

  const onMarkerHover = () => {
    gsap.to("#marker .img", {
      scale: 2.2,
      duration: 1,
      ease: "power3.out",
    });
  };
  const onMarkerMouseOut = () => {
    gsap.to("#marker .img", {
      scale: 1,
      duration: 1,
      ease: "power3.out",
    });
  };

  return (
    <div id="#about" className="min-h-screen bg-white">
      <Navbar light fixed />

      <button
        id="backToTop"
        onClick={goToTop}
        className="fixed bottom-10 right-28 z-[15] shadow-gray-500 invisible hidden md-block"
      >
        <UpIcon width="50px" />
      </button>

      <div id="showcase" className="relative h-screen md:py-14 py-5">
        <div className="hero-img" ref={bgRef}></div>

        {isMobile && (
          <div className="hero-pagination w-full absolute top-[52vh] flex space-x-2 items-center justify-center">
            {TEXTS.map((text, idx) => (
              <div
                key={idx}
                className={`w-3 h-3 rounded-full border border-[#22140C] ${
                  TEXTS[pageState % TEXTS.length] === text
                    ? "bg-[#BF7E58]"
                    : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        )}

        {!isMobile && (
          <div
            id="progress"
            className="absolute bottom-0 right-0 h-[10px] w-[400px] bg-black"
          >
            <div ref={progressRef} className="cover"></div>
          </div>
        )}

        <div
          className={`max-w-6xl mx-auto z-[1] relative h-full flex flex-col justify-end ${
            isMobile ? "items-center" : ""
          }`}
        >
          <div
            className={`${
              !isMobile ? "md:flex-1 md:px-0" : " lg:md-16"
            } flex flex-col justify-center max-w-lg px-4`}
          >
            <h1
              className={`title-large text-[#22140C] max-w-sm mb-2 text-center  ${
                !isMobile ? "md:text-left md:mb-8" : "self-center"
              }`}
            >
              <TextTransition springConfig={presets.gentle}>
                {TEXTS[pageState % TEXTS.length]}
              </TextTransition>
            </h1>

            <p
              className={`text-[#22140C] text-sm text-center  font-light tracking-normal leading-[24px] mb-6 ${
                !isMobile ? "md:text-left md:mb-4" : "self-center"
              }`}
            >
              An architect with over 8 years of experience in the field with a
              Master degree. My experience includes working for three
              Internationally & locally well-known interdisciplinary offices.
            </p>

            <Link
              to={heroData[pageState].path}
              className={`text-[#BF7E58] body-base-strong hover:opacity-70 ${
                isMobile ? "text-center" : "text-left"
              }`}
            >
              {`View ${heroData[pageState].name} projects >`}
            </Link>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:items-center px-4 sm:px-0 w-full">
            <div className="md:w-[300px] w-[200px] hidden md:block">
              <span className="scrollDown"></span>
            </div>

            <div className="self-end mt-6 md:mt-0 hidden md:flex">
              <button
                onClick={onPrevClick}
                // disabled={isChangingPersona}
                className="bg-white rounded-full w-14 h-14 flex items-center justify-center hover:opacity-70 mr-5 disabled:opacity-[.2] disabled:cursor-not-allowed"
              >
                <ArrowLeft />
              </button>
              <button
                onClick={onNextClick}
                // disabled={isChangingPersona}
                className="bg-white rounded-full w-14 h-14 flex items-center justify-center hover:opacity-70 disabled:opacity-[.2]  disabled:cursor-not-allowed"
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="content" className="px-4">
        <div className="md:py-48 py-24" id="about_desc">
          <div className="max-w-3xl mx-auto">
            <p id="text1" className="text-[#22140C] body-base mb-10">
              An architect with over 8 years of experience in the field with a
              Master degree. My experience includes working for three
              Internationally & locally well-known interdisciplinary offices on
              various projects in the MENA region. I have developed very good
              experience and participated in the design and execution of
              multiple schemes ranging from small developments, large mixed-use
              projects, and master plans in the GCC region.
            </p>
            <p id="text2" className="text-[#22140C] body-base">
              The Projects I worked on varies from simple high-end designs to
              highly complex futuristic buildings. The latest role I occupied at
              Killa Design was as lead designer, where I used to lead a team on
              concept competitions and design development. Covering all stages
              starting from designing the initial concepts until the latest
              stages of the project coordination with all involved disciplines
              and client meetings.
            </p>
          </div>
        </div>

        <div className="py-20">
          <div
            id="timeline-section"
            className="mx-auto max-w-6xl flex justify-between w-full relative pb-60"
          >
            <Timeline isMobile={isMobile} />
            {!isMobile && (
              <div id="map" className="flex-1">
                <div
                  id="marker"
                  onMouseOver={onMarkerHover}
                  onMouseOut={onMarkerMouseOut}
                  className="marker"
                >
                  <div className="img"></div>
                  <div className="circle"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
