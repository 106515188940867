export const stats = {
  Earth: {
    designs: 50,
    built: 30,
    awards: 20
  },
  Moon: {
    designs: 6,
    built: 0,
    awards: 4
  },
  Mars: {
    designs: 1,
    built: 0,
    awards: 1
  },
  Asteroid: {
    designs: 1,
    built: 1,
    awards: 1
  },
};
