import { useState, useEffect, useMemo, useCallback } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSmoothScroll } from "../../hooks/useSmoothScroll";
import {
  setActiveIdx,
  setActiveProjectImg,
} from "../../redux/projects/projects.slice";
import { Flip } from "gsap/all";
import { gsap } from "gsap";
import { projects } from "../../data/projects";
import { mainFilters } from "../../data/workFilters";

gsap.registerPlugin(Flip);

const Works = () => {
  useSmoothScroll();
  const [activeMainFilter, setActiveMainFilter] = useState(
    "Architecture (Earth)"
  );
  const [filteredProjects, setfilteredProjects] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMobile = useMemo(() => window.innerWidth <= 1030, []);

  const onProjectClick = useCallback(
    (e, proj) => {
      if (isMobile) {
        dispatch(setActiveIdx(proj.id));
        navigate(`/projects/${proj.id}`);
        return;
      }

      dispatch(setActiveIdx(proj.id));
      let el = e.target;

      // set project image as active (for seamless transition)
      dispatch(setActiveProjectImg(proj.image));

      if (!el.classList.contains("proj-thumbnail")) {
        el = el.previousElementSibling;
      }

      const state = Flip.getState(el);

      gsap.set(el, {
        top: 0,
        left: 0,
        translate: 0,
        position: "fixed",
        opacity: 1,
      });

      el.style.width = "100vw";
      el.style.height = "110vh";

      Flip.from(state, {
        duration: 1.5,
        ease: "expo.inOut",
        absolute: true,
        onComplete: () => {
          navigate(`/projects/${proj.id}`);
        },
      });
    },
    [dispatch, navigate, isMobile]
  );

  const onClickFilter = useCallback(
    (filter) => {
      setActiveMainFilter(filter.tag);

      const items = gsap.utils.toArray(".proj-card");
      const state = Flip.getState(items);

      // Use Set for faster lookups
      const filterSet = new Set(filter.filters);

      // Efficient filtering
      const matches = new Set(
        projects
          .filter(
            (proj) =>
              filterSet.size === 0 ||
              proj.tags.some((tag) => filterSet.has(tag))
          )
          .map((item) => item.ref.current)
      );

      items.forEach((item) => {
        item.style.display = matches.has(item) ? "block" : "none";
      });

      Flip.from(state, {
        duration: 1,
        scale: true,
        absolute: true,
        ease: "power1.inOut",
        onEnter: (elements) =>
          gsap.fromTo(
            elements,
            { opacity: 0, scale: 0 },
            { opacity: 1, scale: 1, duration: 1 }
          ),
        onLeave: (elements) =>
          gsap.to(elements, { opacity: 0, scale: 0, duration: 1 }),
      });
    },
    [projects]
  ); // Keep projects in the dependency array

  useEffect(() => {
    window.scrollTo(0, 0);
    setfilteredProjects(projects);
  }, []);

  useEffect(() => {
    const filter = searchParams.get("search");

    switch (filter) {
      case "architecture":
        onClickFilter({
          tag: "Architecture (Earth)",
          filters: ["Architecture", "Earth"],
        });
        break;
      case "space":
        onClickFilter({
          tag: "Architecture (Space)",
          filters: ["Space"],
        });
        break;
      default:
        return;
    }
  }, [searchParams]);

  return (
    <div id="works" className="min-h-[150vh] bg-white">
      <Navbar light fixed />

      <div id="content" className="pt-28 pb-2 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="md:mb-20 mb-10 mt-4">
            <h1 className="text-6xl font-black text-[#22140C]">Works</h1>
          </div>

          <div className="flex mb-16 relative overflow-x-hidden">
            <div className="flex space-x-4">
              {mainFilters.map((filter) => (
                <button
                  key={filter.id}
                  onClick={() => onClickFilter(filter)}
                  className={`inline-block px-3 py-3 border border-[#93857D] text-[#93857D] text-xs rounded-sm hover:bg-[#F4F0ED]${
                    activeMainFilter === filter.tag ? " bg-[#F4F0ED]" : ""
                  }`}
                >
                  {filter.tag}
                </button>
              ))}

              <button
                onClick={() =>
                  onClickFilter({
                    id: 0,
                    tag: "All",
                    filters: [],
                  })
                }
                className="text-[#93857D] text-xs px-3 py-3 font-semibold hover:bg-[#F4F0ED]"
              >
                &#10005; Reset
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 gap-y-14">
            {filteredProjects.map((proj) => (
              <div
                className="w-full proj-card max-w-[380px]"
                key={proj.id}
                ref={proj.ref}
              >
                <img
                  src={proj.image}
                  alt={proj.title}
                  onClick={(e) => onProjectClick(e, proj)}
                  className="proj-thumbnail w-full h-[250px] inline-block mb-3 rounded-lg cursor-pointer object-cover transition hover:opacity-60 duration-[150ms]"
                />
                <h3
                  onClick={(e) => onProjectClick(e, proj.id)}
                  className="proj-title font-semibold mb-2 text-2xl text-[#22140C] cursor-pointer hover:opacity-60"
                >
                  {proj.title}
                </h3>
                <p className="text-[#93857D] text-xs font-light">
                  {proj.location}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
