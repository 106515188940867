import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSmoothScroll } from "../../hooks/useSmoothScroll";
import useProjects from "../../hooks/useProjects";
import { Flip, CustomEase, ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import {
  setActiveIdx,
  setActiveProjectImg,
} from "../../redux/projects/projects.slice";
import Navbar from "../../components/Navbar/Navbar";
import Slider from "react-slick";
import PageScrollProgress from "../../components/PageScrollProgress/PageScrollProgress";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-right.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Project.css";

gsap.registerPlugin(Flip, CustomEase, ScrollTrigger);

const Project = () => {
  const img1Ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectID } = useParams();
  const { activeProjectImg } = useSelector((state) => state.projects);
  const { project, projects } = useProjects(projectID);
  const [activeProject, setActiveProject] = useState(null);
  const [nextProject, setNextProject] = useState(null);
  const isMobile = window.innerWidth <= 1030;

  useSmoothScroll();

  useEffect(() => {
    const onEsc = (e) => {
      if (e.key === "Escape") {
        navigate("/");
      }
    };
    window.addEventListener("keydown", onEsc);

    return () => window.removeEventListener("keydown", onEsc);
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const blurryDivs = document.querySelectorAll(".blur-bg");
    blurryDivs.forEach((div) => {
      const img = div.querySelector("img");

      function loaded() {
        div.classList.add("loaded");
      }

      if (img.complete) {
        loaded();
      } else {
        img.addEventListener("load", loaded);
      }
    });
  }, [activeProject]);

  useEffect(() => {
    if (project) {
      setActiveProject(project);
      dispatch(setActiveProjectImg(project.image?.url));
    }

    const next = projects.find((proj) => proj.id === parseInt(projectID) + 1);
    if (next) {
      setNextProject(next);
    }
  }, [projectID, dispatch, projects, project]);

  useEffect(() => {
    CustomEase.create("showcase", "0.72, 0.0, 0.28, 1.0");
    CustomEase.create("title", "0.33, 0.0, 0.16, 1.0");
    CustomEase.create("show", "0.33, 0.0, 0.2, 1.0");

    if (!isMobile) {
      gsap.from(".showcase_img", {
        height: "110vh",
        duration: 1,
        delay: 0.15,
        ease: "showcase",
      });
    }

    // const items = document.querySelectorAll(".int_item");
    // const headings = document.querySelectorAll(".sec_heading");
    // const sideImgs = document.querySelectorAll(".img_side");
    // const others = document.querySelectorAll(".others");

    // headings.forEach((item, idx) => {
    //   gsap.from(item, {
    //     y: 60,
    //     autoAlpha: 0,
    //     duration: 1.3,
    //     ease: "show",
    //     scrollTrigger: {
    //       once: true,
    //       trigger: item,
    //       fastScrollEnd: 500,
    //     },
    //     delay: 0.22 * (idx - 1),
    //   });
    // });

    // others.forEach((item, idx) => {
    //   gsap.from(item, {
    //     y: 60,
    //     autoAlpha: 0,
    //     duration: 1.3,
    //     ease: "show",
    //     scrollTrigger: {
    //       once: true,
    //       trigger: item,
    //       fastScrollEnd: 500,
    //     },
    //     delay: 0.22,
    //   });
    // });

    // items.forEach((item, idx) => {
    //   gsap.from(item, {
    //     y: 60,
    //     autoAlpha: 0,
    //     duration: 1.3,
    //     ease: "show",
    //     scrollTrigger: {
    //       trigger: item,
    //       once: true,
    //       fastScrollEnd: 500,
    //     },
    //     delay: 0.22 * (idx - 1),
    //   });
    // });

    // sideImgs.forEach((item, idx) => {
    //   gsap.from(item, {
    //     y: 60,
    //     autoAlpha: 0,
    //     duration: 1.3,
    //     ease: "show",
    //     scrollTrigger: {
    //       trigger: item,
    //       fastScrollEnd: 500,
    //       once: true,
    //     },
    //     delay: 0.22 * (idx - 1),
    //   });
    // });

    // gsap.from("#projectTitle", {
    //   y: 100,
    //   duration: 1,
    //   delay: 0.85,
    //   opacity: 0,
    //   ease: "title",
    // });

    // gsap.from("#intro", {
    //   y: 60,
    //   opacity: 0,
    //   duration: 1,
    //   ease: "show",
    //   scrollTrigger: {
    //     trigger: "#intro",
    //     fastScrollEnd: 500,
    //     once: true,
    //   },
    // });

    // gsap.from("#img1", {
    //   y: 60,
    //   opacity: 0,
    //   ease: "show",
    //   duration: 1.3,
    //   scrollTrigger: {
    //     trigger: "#img1",
    //     once: true,
    //     fastScrollEnd: 500,
    //   },
    //   delay: 0.22 * items.length,
    // });
  }, [projectID]);

  useEffect(() => {
    const toggleNavbarBg = () => {
      if (isMobile) {
        if (window.scrollY > 150) {
          gsap.to("#navbar", {
            background: "#000",
            duration: 0.5,
          });
        } else {
          gsap.to("#navbar", {
            background: "transparent",
            duration: 0.5,
          });
        }
      }
    };

    window.addEventListener("scroll", toggleNavbarBg);
    window.addEventListener("resize", toggleNavbarBg);

    return () => {
      window.removeEventListener("scroll", toggleNavbarBg);
      window.removeEventListener("resize", toggleNavbarBg);
    };
  }, [isMobile]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: isMobile ? 1 : 2.35,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
  };

  const onNextClick = async () => {
    dispatch(setActiveProjectImg(nextProject.image));

    if (isMobile) {
      dispatch(setActiveIdx(nextProject.id));
      navigate(`/projects/${nextProject?.id}`);
      return;
    }

    dispatch(setActiveIdx(nextProject.id));
    // set project image as active (for seamless transition)

    const tl = gsap.timeline({
      paused: true,
      yoyo: true,
      repeat: 1,
      onComplete: () => {
        window.scrollTo(0, 0);
        gsap.set(card, {
          translate: 0,
          transform: "none",
          position: "relative",
          width: "250px",
          height: "170px",
        });
      },
    });

    const card = document.querySelector(`#nextImg`);
    const state = Flip.getState(card);

    tl.add(
      gsap.set(card, {
        top: 0,
        left: 0,
        translate: 0,
        position: "fixed",
      })
    );

    card.style.width = "100vw";
    card.style.height = "110vh";

    CustomEase.create("next", "0.70, 0.00, 0.3, 1.00");

    tl.add(
      Flip.from(state, {
        duration: 1.5,
        ease: "next",
        absolute: true,
        onComplete: () => {
          navigate(`/projects/${nextProject?.id}`);
        },
      })
    );

    tl.add(
      gsap.set(card, {
        translate: 0,
        position: "relative",
        width: "250px",
        height: "170px",
      })
    );

    tl.play();
  };

  return (
    <div id="project">
      <Navbar fixed className="md:bg-transparent" />

      <div id="showcase">
        <div
          className="showcase_img"
          data-flip-id="mainImg"
          style={{
            backgroundImage: `url(${activeProjectImg})`,
          }}
        ></div>
      </div>

      <h1 id="projectTitle" className="text-center w-full title-large mt-10">
        {project?.title}
      </h1>

      {!isMobile && <PageScrollProgress />}

      <div id="content" className="px-4">
        <div className="max-w-5xl mx-auto pt-16">
          <div id="intro">
            <div className="flex md:space-x-8 flex-row flex-wrap md:flex-nowrap gap-y-8 md:gap-y-0 mb-16">
              <div className="int_item md:w-[250px] w-1/2 md:mb-0 mb-6">
                <p className="text-primary caption-small mb-4">Year</p>
                <h4 className="body-xl text-white">{project?.year}</h4>
              </div>

              <div className="int_item md:w-[250px]  w-1/2  md:mb-0 mb-6">
                <p className="text-primary caption-small mb-4">Location</p>
                <h4 className="body-xl text-white">{project?.location}</h4>
              </div>

              <div className="int_item md:w-[250px] w-1/2  md:mb-0 mb-6">
                <p className="text-primary caption-small mb-4">Building Area</p>
                <h4 className="body-xl text-white">{project?.buildingArea}</h4>
              </div>

              <div className="int_item md:w-[250px] w-1/2 ">
                <p className="text-primary caption-small mb-4">Height</p>
                <h4 className="body-xl text-white">{project?.height}</h4>
              </div>
            </div>

            <div className="flex md:space-x-8 flex-row flex-wrap gap-y-8 md:gap-y-0 ">
              <div className="int_item md:w-[500px] w-1/2 pr-20 mb-6 md:mb-0">
                <p className="text-primary caption-small mb-4">Role</p>
                <h4 className="body-large text-[#BBBBBB]">{project?.role}</h4>
              </div>

              <div className="int_item md:w-[250px] w-1/2">
                <p className="text-primary caption-small mb-4">Program</p>
                <h4 className="body-large text-[#BBBBBB]">
                  {project?.specialty}
                </h4>
              </div>
            </div>
          </div>

          <div className="my-32">
            <img
              src={project?.image?.url}
              id="img1"
              alt="a thumbnail of the project"
              className="inline-block w-full h-full pointer-events-none object-cover"
              ref={img1Ref}
              loading="lazy"
            />
          </div>

          {project?.row_one && (
            <>
              <div className="sec_heading flex flex-col md:flex-row items-start">
                <div className="min-w-[250px]">
                  <p className="text-primary text-xs">
                    {project.row_one.title}
                  </p>
                </div>

                <div className="pr-10">
                  <p className="text-md text-[#BBBBBB] tracking-normal font-extralight">
                    {project.row_one.body}
                  </p>
                </div>
              </div>
            </>
          )}

          {project?.portraits && (
            <div className="flex flex-col md:flex-row md:space-x-4 my-32">
              {project?.portraits?.portrait_1 && (
                <div className="img_side md:w-1/2 w-full mb-4 md:mb-0">
                  <img
                    loading="lazy"
                    src={project.portraits.portrait_1.url}
                    alt={`${project.title} portrait 1`}
                    className="inline-block w-full h-full pointer-events-none object-cover"
                  />
                </div>
              )}
              {project?.portraits?.portrait_2 && (
                <div className="img_side md:w-1/2 w-full mb-4 md:mb-0">
                  <img
                    loading="lazy"
                    src={project.portraits.portrait_2.url}
                    alt={`${project.title} portrait 2`}
                    className="inline-block w-full h-full pointer-events-none object-cover"
                  />
                </div>
              )}
            </div>
          )}

          {project?.row_two && (
            <>
              <div className="sec_heading flex flex-col md:flex-row items-start">
                <div className="min-w-[250px]">
                  <p className="text-primary text-xs">
                    {project.row_two.title}
                  </p>
                </div>

                <div className="pr-10">
                  <p className="text-md text-[#BBBBBB] tracking-normal font-extralight">
                    {project.row_two.body}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        {project?.carouselItems && (
          <div className="my-32">
            <Slider {...sliderSettings}>
              {project?.carouselItems?.map((item, idx) => (
                <div className="p-2" key={idx}>
                  <img
                    loading="lazy"
                    src={item.image.url}
                    alt=""
                    className="inline-block w-full h-[400px] pointer-events-none object-cover mb-4"
                  />

                  <h6 className="text-white mb-2 body-base">{item.title}</h6>
                  <p className="text-[#BBBBBB] body-small">{item.subtext}</p>
                </div>
              ))}
            </Slider>
          </div>
        )}

        <div className="max-w-5xl mx-auto">
          {project?.row_three && (
            <>
              <div className="sec_heading flex flex-col md:flex-row items-start">
                <div className="min-w-[250px]">
                  <p className="text-primary text-xs">
                    {project.row_three.title}
                  </p>
                </div>

                <div className="pr-10">
                  <p className="text-md text-[#BBBBBB] tracking-normal font-extralight">
                    {project.row_three.body}
                  </p>
                </div>
              </div>
            </>
          )}

          {project?.textBlocks && (
            <div className="my-32">
              {project?.textBlocks?.map((block, index) => (
                <div
                  key={index}
                  className="others flex flex-col md:flex-row items-start md:space-x-8 mb-6 md:mb-2"
                >
                  <div
                    className="md:w-3/5 w-full blur-bg md:min-h-[300px] mb-6 md:mb-0"
                    style={{
                      backgroundImage: `url(${block.image?.formats.thumbnail?.url})`,
                    }}
                  >
                    <img
                      src={block.image?.url}
                      alt=""
                      loading="lazy"
                      className="inline-block w-full h-full max-h-[400px] pointer-events-none object-cover mb-6"
                    />
                  </div>

                  <div className="md:w-2/5 w-full md:pr-12">
                    <h6 className="text-white mb-2 body-base">{block.title}</h6>
                    <p className="text-[#BBBBBB] body-small">{block.subtext}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {project?.galleryImages && (
          <div className="max-w-5xl mx-auto mb-32">
            <ImageGallery images={project?.galleryImages} />
          </div>
        )}
      </div>

      {nextProject && (
        <div
          className="mt-20 md:py-20 pt-20 pb-32 px-4 bg-gray-900"
          id="nxtProject"
        >
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
            <div className="flex-1 mb-4 md:mb-0">
              <p className="text-white body-base mb-6">NEXT PROJECT</p>

              <h3 className="title-medium text-white mb-6">
                {nextProject?.title || "Next Project"}
              </h3>

              <button
                onClick={onNextClick}
                className="inline-block py-3 text-white border-b border-[#BF7E58] body-base-strong"
              >
                View next project
                <span className="inline-block ml-8 text-white">
                  <ArrowIcon width="10px" height="10px" />
                </span>
              </button>
            </div>

            <div className="h-full">
              <img
                src={nextProject.image}
                alt="next project"
                id="nextImg"
                className="w-[250px] h-[170px] object-cover cursor-pointer"
                onClick={onNextClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Project;
