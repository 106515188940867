import { useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger, Draggable, Flip, CustomEase } from "gsap/all";
import {
  setActiveIdx,
  setActiveProjectId,
  setActivePlanet,
  setActiveProj,
  setActiveProjectImg,
} from "../../redux/projects/projects.slice";
import "./Slider.css";

gsap.registerPlugin(ScrollTrigger, Draggable, Flip, CustomEase);
ScrollTrigger.normalizeScroll(true);

const Slider = ({ containerRef, projects }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeIdx, activePlanet, activeProjectId } = useSelector(
    (state) => state.projects
  );

  const iterationRef = useRef(0);
  const activeRef = useRef(0);
  const cardsRef = useRef([]);
  const seamlessLoopRef = useRef(null);
  const scrubRef = useRef(null);
  const triggerRef = useRef(null);

  const animateFunc = useCallback((element) => {
    const tl = gsap.timeline();
    tl.fromTo(
      element,
      { scale: 0.65 },
      {
        scale: 1,
        zIndex: 100,
        duration: 0.5,
        yoyo: true,
        repeat: 1,
        ease: "power1.in",
        immediateRender: false,
      }
    ).fromTo(
      element,
      { yPercent: 400 },
      { yPercent: -400, duration: 1, ease: "none", immediateRender: false },
      0
    );
    return tl;
  }, []);

  const handleCardChange = useCallback(
    (cards, active) => {
      // Check for active card using zIndex and add classnames
      cards.forEach((card, i) => {
        if (card.style.zIndex > 90) {
          const img = card.querySelector("img");
          card.classList.remove("inactive");
          card.classList.add("active");

          gsap.to(img, {
            opacity: 1,
          });

          dispatch(setActiveIdx(i));
          active = i;

          const activeProj = projects[i];
          if (!activeProj) return;

          // Set active element (for globe)
          dispatch(setActiveProjectId(activeProj.id));
          dispatch(setActiveProj(activeProj.country));
        } else {
          card.classList.remove("active");
          card.classList.add("inactive");

          const img = card.querySelector("img");

          gsap.to(img, {
            opacity: 0.15,
          });
        }
      });

      if (!ScrollTrigger.isScrolling()) {
        // set active planet after scroll settles
        const activeplanet = projects[active]?.planet;
        if (activeplanet) {
          dispatch(setActivePlanet(activeplanet));
        }
      }
    },
    [dispatch]
  );

  const buildSeamlessLoop = useCallback(
    (items, spacing, animateFunc, active) => {
      let rawSequence = gsap.timeline({
          paused: true,
          onUpdate: () => handleCardChange(items, active),
        }),
        seamlessLoop = gsap.timeline({
          // this merely scrubs the playhead of the rawSequence so that it appears to seamlessly loop
          paused: true,
          repeat: -1, // to accommodate infinite scrolling/looping
          onRepeat() {
            // works around a super rare edge case bug that's fixed GSAP 3.6.1
            this._time === this._dur && (this._tTime += this._dur - 0.01);
          },
          onReverseComplete() {
            this.totalTime(this.rawTime() + this.duration() * 100); // seamless looping backwards
          },
        }),
        cycleDuration = spacing * items.length,
        dur;

      items
        .concat(items)
        .concat(items)
        .forEach((item, i) => {
          let anim = animateFunc(items[i % items.length]);
          rawSequence.add(anim, i * spacing);
          dur || (dur = anim.duration());
        });

      // animate the playhead linearly from the start of the 2nd cycle to its end (so we'll have one "extra" cycle at the beginning and end)
      seamlessLoop.fromTo(
        rawSequence,
        {
          time: cycleDuration + dur / 2,
        },
        {
          time: "+=" + cycleDuration,
          duration: cycleDuration,
          ease: "none",
        }
      );
      return seamlessLoop;
    },
    [handleCardChange]
  );

  // Helper functions
  const wrap = useCallback((iterationDelta, scrollTo) => {
    iterationRef.current += iterationDelta;
    triggerRef.current.scroll(scrollTo);
    triggerRef.current.update();
  }, []);

  const scrollToOffset = useCallback(
    (offset) => {
      const spacing = 0.125;
      const snapTime = gsap.utils.snap(spacing);
      const snappedTime = snapTime(offset);
      const progress =
        (snappedTime -
          seamlessLoopRef.current.duration() * iterationRef.current) /
        seamlessLoopRef.current.duration();
      const scroll = progressToScroll(progress);

      if (progress >= 1 || progress < 0) {
        return wrap(Math.floor(progress), scroll);
      }
      triggerRef.current.scroll(scroll);
    },
    [wrap]
  );

  const progressToScroll = useCallback(
    (progress) =>
      gsap.utils.clamp(
        1,
        triggerRef.current.end - 1,
        gsap.utils.wrap(0, 1, progress) * triggerRef.current.end
      ),
    []
  );

  const onProjectClick = useCallback(
    async (project) => {
      const id = project.id;
      if (id !== activeProjectId) return;

      // set project image as active (for seamless transition)
      dispatch(setActiveProjectImg(project.image.url));

      const card = document.querySelector(`.card.active`);
      const info = card.querySelector("#cardInfo");
      card.removeChild(info);

      const state = Flip.getState([card, ".card.active::after"], {
        props: "transform",
      });

      // Set layout for transition
      gsap.set(".cards", {
        top: 0,
        left: 0,
        translate: 0,
        position: "fixed",
        width: "auto",
        height: "auto",
      });
      gsap.set(".gallery", {
        top: 0,
      });
      gsap.set([".card.inactive", "#stats", "#titles"], {
        autoAlpha: 0,
        duration: 1,
      });
      gsap.to(["#stats", "#titles"], {
        autoAlpha: 0,
      });

      const tl = gsap.timeline();

      // change styles
      gsap.set(card, {
        width: "100vw",
        height: "110vh",
        position: "fixed",
        top: 0,
        left: 0,
      });

      tl.add(
        Flip.from(state, {
          duration: 1.5,
          ease: "slider",
          absolute: true,
          onComplete: () => {
            ScrollTrigger.killAll();
            navigate(`/projects/${project.documentId}`);
          },
        })
      );
    },
    [dispatch, activeProjectId, navigate]
  );

  // Effect for initial setup
  useEffect(() => {
    gsap.set(".cards li", { yPercent: 400, scale: 0 });
    cardsRef.current = gsap.utils.toArray(".cards li");
  }, [projects]);

  // Effect for creating seamless loop
  useEffect(() => {
    const spacing = 0.125;
    if (projects.length > 0) {
      seamlessLoopRef.current = buildSeamlessLoop(
        cardsRef.current,
        spacing,
        animateFunc,
        activeRef.current
      );
    }
  }, [buildSeamlessLoop, animateFunc, projects]);

  // Effect for setting up scrub animation
  useEffect(() => {
    if (projects.length > 0) {
      const playhead = { offset: 0 };
      const wrapTime = gsap.utils.wrap(0, seamlessLoopRef.current.duration());

      scrubRef.current = gsap.to(playhead, {
        offset: 0,
        onUpdate() {
          seamlessLoopRef.current.time(wrapTime(playhead.offset));
        },
        duration: 0.5,
        ease: "power3",
        paused: true,
      });
    }
  }, [projects]);

  // Effect for setting up ScrollTrigger
  useEffect(() => {
    const spacing = 0.125;

    if (projects.length > 0) {
      triggerRef.current = ScrollTrigger.create({
        id: "trigger",
        start: 0,
        onEnter() {
          if (activeIdx === 0) return;
          scrollToOffset(scrubRef.current.vars.offset + spacing * activeIdx);
        },
        onUpdate(self) {
          let scroll = self.scroll();
          if (scroll > self.end - 1) {
            wrap(1, 1);
          } else if (scroll < 1 && self.direction < 0) {
            wrap(-1, self.end - 1);
          } else {
            scrubRef.current.vars.offset =
              (iterationRef.current + self.progress) *
              seamlessLoopRef.current.duration();
            scrubRef.current.invalidate().restart();
          }
        },
        end: "+=3000",
        pin: ".gallery",
      });
    }

    return () => {
      triggerRef.current && triggerRef.current.kill();
    };
  }, [projects]);

  // Effect for scroll end listener
  useEffect(() => {
    const handleScrollEnd = () => {
      if (scrubRef.current.vars) {
        scrollToOffset(scrubRef.current.vars.offset);
      }
    };
    ScrollTrigger.addEventListener("scrollEnd", handleScrollEnd);

    return () => {
      ScrollTrigger.removeEventListener("scrollEnd", handleScrollEnd);
    };
  }, []);

  // Effect for setting up Draggable
  useEffect(() => {
    Draggable.create(".drag-proxy", {
      type: "y",
      trigger: ".cards",
      onPress() {
        this.startOffset = scrubRef.current.vars.offset;
      },
      onDrag() {
        scrubRef.current.vars.offset =
          this.startOffset + (this.startY - this.y) * 0.001;
        scrubRef.current.invalidate().restart();
      },
      onDragEnd() {
        scrollToOffset(scrubRef.current.vars.offset);
      },
    });
  }, []);

  // Effect for marker click listener
  useEffect(() => {
    const spacing = 0.125;
    const handleMarkerClick = (e) => {
      const id = e.detail.id;
      scrollToOffset(
        scrubRef.current.vars.offset + spacing * (id - activeRef.current)
      );
      activeRef.current = id;
    };

    document.addEventListener("marker-click", handleMarkerClick);

    return () => {
      document.removeEventListener("marker-click", handleMarkerClick);
    };
  }, []);

  useEffect(() => {
    const spacing = 0.125;
    const index = projects.findIndex(
      (proj) => proj.planet.toLowerCase() === activePlanet.toLowerCase()
    );
    if (index !== -1) {
      scrollToOffset(spacing * index);
    }
  }, [activePlanet, projects, scrollToOffset]);

  return (
    <div id="slider" ref={containerRef} className="fixed top-0 left-0">
      <div className="gallery">
        <ul className="cards">
          {projects.map((project, idx) => (
            <li
              key={idx}
              className="card bg-black"
              onClick={() => onProjectClick(project)}
            >
              <img
                id={`img-${idx}`}
                src={project.image?.url}
                key={idx}
                loading="lazy"
                alt="project"
                className="inline-block w-full h-full object-cover"
                data-flip-id={project.id === activeProjectId ? "mainImg" : ""}
              />

              {project.id === activeProjectId && (
                <div
                  id="cardInfo"
                  className="relative z-10 w-full h-full p-6 flex flex-col justify-end gap-1"
                >
                  <h6 className="text-2xl font-bold capitalize">
                    {project.title}
                  </h6>
                  <p className="text-[#aaa] body-base">
                    {project.specialty} | {project.location}
                  </p>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="drag-proxy absolute invisible"></div>
    </div>
  );
};

export default Slider;
