import { createSlice } from "@reduxjs/toolkit";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    activeProj: "UAE",
    activeProjectImg: "",
    activeIdx: 0,
    activeProjectId: 0,
    activePlanet: "Earth",
    globeState: 0,
    pageHeader: "HOME",
  },
  reducers: {
    setActiveProj: (state, action) => {
      state.activeProj = action.payload;
    },
    setActiveProjectImg: (state, action) => {
      state.activeProjectImg = action.payload;
    },
    setActiveProjectId: (state, action) => {
      state.activeProjectId = action.payload;
    },
    setActiveIdx: (state, action) => {
      state.activeIdx = action.payload;
    },
    setActivePlanet: (state, action) => {
      state.activePlanet = action.payload;
    },
    setGlobeState: (state, action) => {
      state.globeState = action.payload;
    },
    setPageHeader: (state, action) => {
      state.pageHeader = action.payload;
    },
  },
});

export const {
  setActiveProj,
  setActiveProjectImg,
  setActiveProjectId,
  setActiveIdx,
  setActivePlanet,
  setGlobeState,
  setPageHeader,
} = projectsSlice.actions;

export default projectsSlice.reducer;
