import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PageScrollProgress = ({ dark }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const circle = document.querySelector(".scroll_progress");
    const radius = circle.r.baseVal.value;
    const c = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${c} ${c}`;
    circle.style.strokeDashoffset = `${c}`;

    let progress = window.scrollY / document.body.clientHeight / 100;

    const handleScroll = () => {
      progress =
        c -
        ((window.scrollY + window.innerHeight) / document.body.clientHeight) *
          c;
      if (window.scrollY === 0) progress = c;
      if (progress < 0) progress = 0;
      circle.style.strokeDashoffset = `${progress}`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    navigate(location.pathname.includes("project") ? "/" : -1);
  };

  return (
    <button onClick={handleClick} className="fixed top-6 right-6 z-[15]">
      <svg
        className="progress"
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="34"
          cy="34"
          r="31"
          stroke={dark ? "black" : "white"}
          fill="transparent"
        />
        <circle
          className="scroll_progress"
          cx="34"
          cy="34"
          r="31"
          fill="transparent"
          stroke="#D08960"
          strokeWidth="1"
        />
        <line
          x1="26.5303"
          y1="26.4697"
          x2="42.5303"
          y2="42.4697"
          stroke={dark ? "black" : "white"}
          strokeWidth="1.5"
        />
        <line
          x1="42.5303"
          y1="26.5303"
          x2="26.5303"
          y2="42.5303"
          stroke={dark ? "black" : "white"}
          strokeWidth="1.5"
        />
      </svg>
    </button>
  );
};

export default PageScrollProgress;
