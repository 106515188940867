export const mainFilters = [
  {
    id: 1,
    tag: "Architecture (Earth)",
    filters: ["Architecture", "Earth"],
  },
  {
    id: 2,
    tag: "Architecture (Space)",
    filters: ["Space"],
  },
  {
    id: 5,
    tag: "Research",
    filters: ["Research"],
  },
  {
    id: 3,
    tag: "Robotics",
    filters: ["Robotics"],
  },
  {
    id: 4,
    tag: "Entrepreneurship",
    filters: ["Entrepreneurship"],
  },
  {
    id: 6,
    tag: "Patents",
    filters: ["Patent"],
  },
];

export const locationFilters = [
  {
    label: "Any",
    value: "any",
  },
  {
    label: "UAE",
    value: "UAE",
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "Canada",
    value: "Canada",
  },
  {
    label: "France",
    value: "France",
  },
];
