import React from "react";
import { MotionPathPlugin, gsap } from "gsap/all";
import loaderGif from "../../assets/loader.mp4";
// import Stars from "../Globe/Stars";
import "./Loader.css";

gsap.registerPlugin(MotionPathPlugin);

const Loader = React.forwardRef((props, ref) => {
  return (
    <div
      id="preloader"
      ref={ref}
      className="fixed top-0 left-0 bg-black w-screen h-screen z-10"
    >
      {/* <Stars /> */}

      <div className="w-full h-full flex-items-center-justify-center">
        <video autoPlay loop muted playsInline className="h-full w-full">
          <source src={loaderGif} type="video/mp4" />
          Loading, please wait...
        </video>
      </div>
    </div>
  );
});

export default Loader;
