import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobeState,
  setActiveProjectImg,
} from "../../redux/projects/projects.slice";
import { projects } from "../../data/projects";
import { stats } from "../../data/stats";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { gsap } from "gsap";
import { ScrollTrigger, Flip, ScrollToPlugin, CustomEase } from "gsap/all";
import Globe from "../../components/Globe/Globe";
import Navbar from "../../components/Navbar/Navbar";
import Slider from "../../components/Slider/Slider";
import MobileCarousel from "../../components/MobileCarousel/MobileCarousel";
import Counter from "../../components/Counter/Counter";
import GlobeMobile from "../../components/GlobeMobile/GlobeMobile";
import "./Home.css";

gsap.registerPlugin(Flip, ScrollTrigger, ScrollToPlugin);

const Home = () => {
  const isMobile = window.innerWidth < 1030;
  const tlRef = useRef;
  const navigate = useNavigate();
  const [currentStats, setCurrentStats] = useState(stats.Earth);
  const { globeState, activeIdx } = useSelector((state) => state.projects); // 0: Default state, 1: Viewing Projects
  const [activeProject, setActiveProject] = useState(projects[activeIdx]);
  const dispatch = useDispatch();
  const titleRef = useRef();
  const descRef = useRef();
  const btnRef = useRef();
  const navTitleRef = useRef();
  const infoRef = useRef();
  const statsRef = useRef();
  const titlesRef = useRef();
  const cardsRef = useRef();
  const sliderRef = useRef(null);

  CustomEase.create("slider", "0.70, 0.00, 0.3, 1.00");

  useEffect(() => {
    const handleScroll = (e) => {
      if (globeState === 0 && window.scrollY > 5) {
        const changeState = new CustomEvent("change-globe-state", {
          detail: {},
          bubbles: true,
          cancelable: true,
          composed: false,
        });
        document.querySelector("#home").dispatchEvent(changeState);
        dispatch(setGlobeState(1));

        if (isMobile) {
          const globeImg = document.querySelector("#globe-img");

          gsap.to(globeImg, {
            duration: 2,
            ease: "move",
            top: window.innerWidth < 500 ? "5%" : 0,
            left: 0,
            width: "100%",
          });
        }
      }
    };

    document.addEventListener("scroll", handleScroll);
    window.history.scrollRestoration = "manual";

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, globeState, isMobile]);

  useEffect(() => {
    const title = titleRef.current;
    const desc = descRef.current;

    if (globeState === 1) {
      infoRef.current.style.display = "none";
    }

    const tl = gsap.timeline();

    tl.from(title, {
      duration: 0.5,
      autoAlpha: 0,
      y: 50,
    });
    tl.from(desc, {
      duration: 0.3,
      autoAlpha: 0,
      y: 50,
    });
  }, []);

  useEffect(() => {
    tlRef.current = new gsap.timeline({
      ease: "power2.out",
      paused: true,
    });
    const tl = tlRef.current;

    tl.to(infoRef.current, {
      duration: 1,
      y: -700,
      autoAlpha: 0,
    });

    tl.from(cardsRef.current, {
      autoAlpha: 0,
      y: 50,
      duration: 1,
      delay: 1,
    });

    tl.to(navTitleRef.current, {
      duration: 0.5,
      autoAlpha: 1,
    });

    tl.to(statsRef.current, {
      duration: 0.5,
      autoAlpha: 0.5,
    });

    tl.to(titlesRef.current, {
      duration: 0.5,
      // y: 500,
      autoAlpha: 1,
    });
  }, [tlRef]);

  useEffect(() => {
    if (globeState === 1) {
      tlRef.current.play();
    }
  }, [globeState, tlRef]);

  useEffect(() => {
    setActiveProject(projects[activeIdx]);
  }, [activeIdx]);

  useEffect(() => {
    if (activeProject.planet) {
      setCurrentStats(stats[activeProject.planet]);
    }
  }, [activeProject]);

  const onViewProjects = () => {
    CustomEase.create("move", "0.60, 0.00, 0.40, 1.00");
    dispatch(setGlobeState(1));

    if (isMobile) {
      const globeImg = document.querySelector("#globe-img");

      gsap.to(globeImg, {
        duration: 2,
        ease: "move",
        top: window.innerWidth < 500 ? "5%" : 0,
        left: 0,
        width: "100%",
      });
    }
  };

  const onProjectClick = useCallback(async () => {
    const project = projects[activeIdx];

    // set project image as active (for seamless transition)
    dispatch(setActiveProjectImg(project.image));

    const card = document.querySelector(`.card.active`);

    const state = Flip.getState([card, ".card.active::after"], {
      props: "transform",
    });

    // Set layout for transition
    gsap.set(".cards", {
      top: 0,
      left: 0,
      translate: 0,
      position: "fixed",
      width: "auto",
      height: "auto",
    });
    gsap.set(".gallery", {
      top: 0,
    });
    gsap.set([".card.inactive", "#stats", "#titles"], {
      autoAlpha: 0,
      duration: 1,
    });
    gsap.to(["#stats", "#titles"], {
      autoAlpha: 0,
    });

    const tl = gsap.timeline();

    // change styles
    gsap.set(card, {
      width: "100vw",
      height: "110vh",
      position: "fixed",
      top: 0,
      left: 0,
    });

    tl.add(
      Flip.from(state, {
        duration: 1.5,
        ease: "slider",
        absolute: true,
        onComplete: () => {
          ScrollTrigger.killAll();
          navigate(`/projects/${project.id}`);
        },
      })
    );
  }, [dispatch, activeIdx, navigate]);

  const onProjectClickMobile = useCallback(() => {
    sliderRef.current.slickPause();
    const project = projects[activeIdx];

    // set project image as active (for seamless transition)
    dispatch(setActiveProjectImg(project.image));
    navigate(`/projects/${project.id}`);
  }, [dispatch, activeIdx, navigate]);

  return (
    <div id="home" className="relative min-h-[500vh]">
      <Navbar fixed titleRef={navTitleRef} />

      <div id="content">
        <div
          id="info"
          ref={infoRef}
          className="fixed w-full flex justify-center text-center md:px-32 mt-36 z-[5]"
        >
          <div className="w-full md:max-w-lg px-5 md:px-0">
            <h1 className="title-large mb-5 md:mb-10" ref={titleRef}>
              Hi, I’m Hatem
            </h1>
            <p
              className="body-base text-white md:text-[#BBBBBB] mb-5 md:mb-10"
              ref={descRef}
            >
              An{" "}
              <span className="body-base-strong">
                award winning space architect
              </span>{" "}
              and an aspiring astronaut who has designed and built some of the
              world’s best architecture masterpieces.
            </p>

            <button
              ref={btnRef}
              id="viewProjects"
              className="inline-block border border-white px-10 py-3 text-white rounded-sm body-base-strong hover:bg-white hover:text-black hover:opacity-80"
              onClick={onViewProjects}
            >
              View projects
            </button>
          </div>
        </div>

        {!isMobile ? <Globe /> : <GlobeMobile />}

        {isMobile ? (
          <MobileCarousel
            containerRef={cardsRef}
            sliderRef={sliderRef}
            onProjectClick={onProjectClickMobile}
          />
        ) : (
          <Slider containerRef={cardsRef} onProjectClick={onProjectClick} />
        )}

        <div
          id="stats"
          className={`fixed bottom-[8vh] w-[300px] invisible pointer-events-none py-3 hidden xl:block`}
          ref={statsRef}
        >
          <div className="flex items-center justify-between">
            <div className="text-center">
              <Counter num={currentStats?.designs} />
              <p className="text-[#aaa] body-base">designs</p>
            </div>
            <div className="text-center">
              <Counter num={currentStats?.built} />
              <p className="text-[#aaa] body-base">built</p>
            </div>
            <div className="text-center">
              <Counter num={currentStats?.awards} />
              <p className="text-[#aaa] body-base">awards</p>
            </div>
          </div>
        </div>

        <div
          id="titles"
          className={`fixed invisible py-2 px-5 ${
            isMobile
              ? "w-full bottom-[10px] md:px-14"
              : "w-[350px] bottom-[8vh] left-[66vw]"
          }`}
          ref={titlesRef}
        >
          <button
            id="projectTitle"
            onClick={() =>
              isMobile ? onProjectClickMobile() : onProjectClick()
            }
            className="title-medium flex w-full justify-between items-center mb-2 md:mb-3 hover:opacity-70 cursor-pointer"
          >
            {activeProject?.title}
            <ArrowIcon width={"15px"} className="inline-block ml-4" />
          </button>
          <p className="text-[#aaa] body-base mt-4 md:text-left">
            {activeProject?.specialty} | {activeProject?.location}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
