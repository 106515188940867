import { useState, useEffect } from "react";
import axios from "axios";

export default function useProjects(projectId) {
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);

  useEffect(() => {
    async function fetchProjects() {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/projects?pLevel`);
      const projects = res.data.data;
      setProjects(projects);
    }

    fetchProjects();
  }, []);

  useEffect(() => {
    async function fetchProject() {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/projects/${projectId}?pLevel`
      );
      const project = res.data.data;
      setProject(project);
    }

    if (projectId) {
      fetchProject();
    }
  }, [projectId]);

  return {
    projects,
    project,
  };
}
