import React from "react";
import { useDispatch } from "react-redux";
// import Slider from "react-slick";
import {
  setActiveIdx,
  setActivePlanet,
  setActiveProjectId,
  setActiveProj,
} from "../../redux/projects/projects.slice";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./MobileCarousel.css";

const MobileCarousel = ({
  containerRef,
  sliderRef,
  onProjectClick,
  projects,
}) => {
  const dispatch = useDispatch();
  const onActiveCardChange = (index) => {
    const active = projects[index];
    if (!active) return;
    dispatch(setActiveProjectId(active.id));
    dispatch(setActiveProj(active.country));
    dispatch(setActiveIdx(index));
    dispatch(setActivePlanet(active.planet));
  };

  // const sliderSettings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 2500,
  //   slidesToShow: window.innerWidth < 768 ? 1.003 : 2.35,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   arrows: false,
  //   autoplaySpeed: 3000,
  //   centerMode: true,
  //   cssEase: "linear",
  //   draggable: true,
  //   swipeToSlide: true,

  //   className: "active",
  //   // pauseOnHover: true,
  //   afterChange: onActiveCardChange,
  // };

  const responsive = {
    0: { items: 1 },
    568: { items: 3 },
  };

  return (
    <div
      ref={containerRef}
      id="mobileCarousel"
      className="fixed top-[55vh] sm:top-[67vh] left-0 w-screen h-screen"
    >
      <div className="relative top-0">
        {/* <Slider
          {...sliderSettings}
          ref={(slider) => {
            sliderRef.current = slider;
          }}
        >
          {projects.map((project, idx) => (
            <li
              key={idx}
              className="card w-full h-48 sm:h-56 md:h-64"
              onClick={() => onProjectClick()}
            >
              <img
                id={`img-${idx}`}
                src={project.image?.url}
                key={idx}
                alt="project"
                className="inline-block w-full h-full object-cover m-2"
              />
            </li>
          ))}
        </Slider> */}

        <AliceCarousel
          autoPlay
          autoPlayStrategy="none"
          autoPlayInterval={1000}
          animationDuration={1000}
          animationType="slide"
          infinite
          items={projects.map((project, idx) => (
            <li
              key={idx}
              data-value={idx}
              className="card w-full h-[220px] sm:h-56 md:h-64 item px-4"
              onClick={() => onProjectClick()}
            >
              <img
                id={`img-${idx}`}
                src={project.image?.url}
                key={idx}
                alt="project"
                className="inline-block w-full h-full object-cover"
              />
            </li>
          ))}
          responsive={responsive}
          disableDotsControls
          disableButtonsControls
          onSlideChanged={(e) => {
            const index = e.slide;
            onActiveCardChange(index);
          }}
        />
      </div>
    </div>
  );
};

export default MobileCarousel;
