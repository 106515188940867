import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Stars from "../../components/Globe/Stars";
import spaceman from "../../assets/spaceman.png";
import './PageNotFound.css'

const PageNotFound = () => {
  return (
    <div>
      <Navbar fixed />
      <Stars />

      <div className="w-screen h-screen relative z-[2] flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-6xl font-black mb-8">404</h1>

          <p className="text-sm text-white font-light tracking-normal mb-8">
            It looks like you’re lost in space
          </p>

          <Link
            to="/"
            className="inline-block bg-white px-7 py-3 text-[#020009] rounded-sm text-xs font-semibold hover:opacity-[.6]"
          >
            Get back home
          </Link>
        </div>

        <div id="box-astronaut">
          <img id="astronaut" src={spaceman} alt="spaceman" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
