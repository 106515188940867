import { useEffect } from "react";
import { MotionPathPlugin, gsap } from "gsap/all";
import { useDispatch, useSelector } from "react-redux";
import { setPreloaderAnimateComplete } from "../../redux/globe/globe.slice";
import Stars from "../Globe/Stars";
import SplitType from "split-type";
import "./Loader.css";

gsap.registerPlugin(MotionPathPlugin);

const Loader = () => {
  const dispatch = useDispatch();
  const { loaded: globeLoaded } = useSelector((state) => state.globe);

  useEffect(() => {
    if (globeLoaded) {
      document.getElementById("loaderBody").style.display = "none";
    }
  }, []);

  useEffect(() => {
    const items = gsap.utils.toArray(".welcome-item");

    const tl = gsap.timeline({
      paused: true,
      repeat: -1,
    });

    items.forEach((el, i) => {
      let letters;
      if (i === 1) {
        letters = el;
      } else {
        letters = new SplitType(el).chars;
      }

      tl.add(
        gsap.from(letters, {
          duration: 0.7,
          y: 30,
          opacity: 0,
          repeat: 1,
          yoyo: true,
          stagger: 0.7 / 10,
          repeatDelay: 1,
        })
      );
    });

    tl.play();
  }, []);

  useEffect(() => {
    const circle = document.querySelector("#preloader .load_progress");
    const rocket = document.querySelector("#preloader #rocket");
    const radius = circle.r.baseVal.value;
    const c = radius * 2 * Math.PI;
    circle.style.strokeDasharray = `${c} ${c}`;

    const startTime = performance.now();

    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    const tl = gsap.timeline({
      onComplete: () => {
        // Setting animation complete status so loader can be phased out properly
        dispatch(setPreloaderAnimateComplete(true));
      },
      onUpdate: () => {
        const elapsedTime = (performance.now() - startTime) / 1000; // Convert to seconds

        if (elapsedTime >= 3) {
          return;
        }

        const easedTime = easeInOutQuad(elapsedTime / 3);

        // Calculate angle based on eased time
        const angle = easedTime * 330;

        // Calculate translation
        let translateX = 34 + 34 * Math.cos(angle * (Math.PI / 180));
        let translateY = 34 + 34 * Math.sin(angle * (Math.PI / 180));

        // Adjust translate values for special cases
        if (Math.abs(angle) < 1 || Math.abs(angle - 360) < 1) {
          translateY -= 5;
        }

        // Apply rotation and translation to rocket
        const rotation = angle + 90;
        rocket.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotation}deg) scale(0.2)`;
      },
    });

    tl.add(
      gsap.to(circle, {
        strokeDashoffset: -(c - 15),
        duration: 3,
        ease: "power1.inOut",
        // onComplete: () => {
        //   document.querySelector("#pctCount").textContent = "95%";
        // },
      }),
      0
    );

    return () => {};
  }, [dispatch]);

  return (
    <div
      id="preloader"
      className="fixed top-0 left-0 bg-black w-screen h-screen z-10"
    >
      <Stars />

      <div
        id="loaderBody"
        className={`w-full h-full flex flex-col items-center justify-center relative`}
      >
        <svg
          id="preloadSVG"
          className="mb-2"
          width="400"
          height="400"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="34"
            cy="34"
            r="31"
            stroke="white"
            strokeWidth={0.5}
            fill="transparent"
          />
          <circle
            className="load_progress"
            cx="34"
            cy="34"
            r="31"
            fill="transparent"
            stroke="#6A6A6A"
            strokeWidth="0.5"
          />

          <g id="rocket" width="100px" height="100px" fill="black">
            <path
              d="M8.51646 14.8787C6.56484 14.709 3.56671 17 3.56671 17C3.56671 17 6.56484 19.291 8.51646 19.1213C9.61247 19.0294 10.5176 18.1101 10.5105 17C10.498 16.4628 10.2875 15.9492 9.9195 15.5577C9.55148 15.1662 9.05184 14.9244 8.51646 14.8787ZM14.8804 19.1213V14.8787C16.2329 14.2787 17.6425 13.8168 19.0877 13.4998C21.229 13.0064 23.4609 13.0689 25.5711 13.6816C27.6813 14.2942 29.5998 15.4366 31.1439 17C29.2205 18.9233 25.289 21.7517 19.1231 20.5355C17.6651 20.2071 16.2438 19.7334 14.8804 19.1213Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.8794 14.8787L11.3439 11.3431C11.3439 11.3431 13.8753 9.5895 15.5865 9.92891C17.4957 10.3107 19.1221 13.4644 19.1221 13.4644M14.8794 19.1213L11.3439 22.6568C11.3439 22.6568 13.8753 24.4105 15.5865 24.071C17.4957 23.6892 19.1221 20.5355 19.1221 20.5355"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>

        <div
          id="welcome"
          className="welcome absolute top-[47%] text-center overflow-hidden h-[30px] w-[200px]"
        >
          <ul className="relative welcome-items">
            <li className="welcome-item text-white text-2xl">Welcome</li>
            <li className="welcome-item text-white text-2xl">مرحباً</li>
            <li className="welcome-item text-white text-2xl">
              いらっしゃいませ
            </li>
            <li className="welcome-item text-white text-2xl">Bienvenido</li>
          </ul>
        </div>

        <p id="pctCount" className="text-white text-sm">
        </p>
      </div>
    </div>
  );
};

export default Loader;
