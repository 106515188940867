import { useRef, useEffect } from "react";
import { projects } from "../../data/projects";
import { useSelector, useDispatch } from "react-redux";
import { setGlobeLoaded } from "../../redux/globe/globe.slice";
import gsap from "gsap";
import Globe from "./globe.lib";
import Stars from "./Stars";
import Loader from "../Loader/Loader";

export default function GlobeI() {
  const dispatch = useDispatch();
  const globeRef = useRef();
  const containerRef = useRef();
  const switchRef = useRef();
  const isMobile = window.innerWidth < 1030;
  const { preloaderAnimationComplete, loaded: globeLoaded } = useSelector(
    (state) => state.globe
  );
  const { activeProj, activePlanet, globeState } = useSelector(
    (state) => state.projects
  );

  useEffect(() => {
    const globeProperties = {
      earth: [],
      mars: [],
      moon: [],
      asteroid: [],
    };
    projects.forEach((proj, idx) => {
      globeProperties[proj.planet.toLowerCase()].push({
        lon: proj.longitude,
        lat: proj.latitude,
        name: proj.location,
        planet: proj.planet.toLowerCase(),
      });
    });

    globeRef.current = new Globe(
      containerRef.current,
      globeState,
      activePlanet.toLowerCase(),
      globeProperties,
      isMobile
    );
    globeRef.current.init();

    return () => {
      globeRef.current.cleanup();
    };
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth < 1030;
    globeRef.current.setIsMobile(isMobile);

    window.addEventListener("resize", () => {
      const isMobile = window.innerWidth < 1030;
      globeRef.current.setIsMobile(isMobile);
    });

    return () => {
      window.removeEventListener("resize", () => {
        const isMobile = window.innerWidth < 1030;
        globeRef.current.setIsMobile(isMobile);
      });
    };
  }, []);

  useEffect(() => {
    const switchProjectEvent = new CustomEvent("switch-project", {
      detail: {
        country: activeProj,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    switchRef.current.dispatchEvent(switchProjectEvent);
  }, [activeProj]);

  useEffect(() => {
    const switchPlanetEvent = new CustomEvent("switch-planet", {
      detail: {
        mode: activePlanet.toLowerCase(),
        adjustSlider: false,
      },
      bubbles: false,
      cancelable: true,
      composed: false,
    });

    const toggleMarker = new CustomEvent("toggle-markers", {
      detail: {
        mode: activePlanet.toLowerCase(),
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    switchRef.current.dispatchEvent(switchPlanetEvent);
    switchRef.current.dispatchEvent(toggleMarker);
  }, [activePlanet]);

  useEffect(() => {
    document.addEventListener("models-loaded", (e) => {
      dispatch(setGlobeLoaded(true));
    });
  }, [dispatch]);

  useEffect(() => {
    // If all conditions are right, clear preloader
    if (preloaderAnimationComplete && globeLoaded) {
      const circle = document.querySelector("#preloader .load_progress");
      const rocket = document.querySelector("#preloader #rocket");
      const radius = circle.r.baseVal.value;
      const c = radius * 2 * Math.PI;

      const startTime = performance.now();

      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }

      gsap.to(circle, {
        strokeDashoffset: -c,
        duration: 0.5,
        ease: "power1.inOut",
        onUpdate: () => {
          const elapsedTime = (performance.now() - startTime) / 1000; // Convert to seconds

          if (elapsedTime >= 0.5) {
            return;
          }

          // Apply easing function to elapsed time
          const easedTime = easeInOutQuad(elapsedTime / 0.5);

          // Calculate angle based on eased time
          const angle = 330 + easedTime * (355 - 330); // From 330 to 360 degrees

          // Calculate translation
          let translateX = 34 + 34 * Math.cos(angle * (Math.PI / 180));
          let translateY = 34 + 34 * Math.sin(angle * (Math.PI / 180));

          // Adjust translate values for special cases
          if (Math.abs(angle) < 1 || Math.abs(angle - 360) < 1) {
            translateY -= 5;
          }

          // Apply rotation and translation to rocket
          const rotation = angle + 90;
          rocket.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotation}deg) scale(0.2)`;
        },
      });

      globeRef.current.clearPreloader();
    }
  }, [preloaderAnimationComplete, globeLoaded]);

  return (
    <div id="globe">
      <Stars />
      <div className="w-screen h-screen fixed" ref={containerRef}></div>

      <Loader />

      {/* Button used to fire all globe-related custom events */}
      <button id="switch" ref={switchRef} className="invisible"></button>
    </div>
  );
}
