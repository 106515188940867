import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Stars from "../Globe/Stars";

const GlobeMobile = () => {
  const { globeState } = useSelector((state) => state.projects); //
  const [currentClass, setCurrentClass] = useState(
    "fixed max-w-none object-cover sm:bottom-[-45%] sm:left-[-20%] sm:w-[150vw] bottom-[-55vh] left-[-45%] w-[200vw]"
  );

  useEffect(() => {
    if (globeState === 1) {
      setCurrentClass(
        "fixed max-w-none object-cover sm:top-0 top-[5%] left-0 w-full"
      );
    }
  }, []); // Intentionally left empty as we only want to run this once

  return (
    <div className="globe_mobile">
      <Stars />

      <div className="w-screen h-screen fixed">
        <img
          src="https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/npzxaijada4qaf0p9ucp"
          alt="globe-hd"
          id="globe-img"
          className={currentClass}
        />
      </div>
    </div>
  );
};

export default GlobeMobile;
