import React, { useEffect, useRef } from "react";

const Stars = () => {
  const canvasRef = useRef();

  useEffect(() => {
    var canvas = canvasRef.current;

    var ctx = canvas.getContext("2d"),
      w = (canvas.width = window.innerWidth),
      h = (canvas.height = window.innerHeight),
      // hue = 150,
      stars = [],
      count = 0,
      maxStars = 1600;

    var canvas2 = document.createElement("canvas"),
      ctx2 = canvas2.getContext("2d");
    canvas2.width = 100;
    canvas2.height = 100;
    var half = canvas2.width / 2,
      gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
    gradient2.addColorStop(0.025, "#fff");
    gradient2.addColorStop(0.1, "#fff");
    gradient2.addColorStop(0.9, "transparent");
    gradient2.addColorStop(1, "transparent");

    ctx2.fillStyle = gradient2;
    ctx2.beginPath();
    ctx2.arc(half, half, half, 0, Math.PI * 2);
    ctx2.fill();

    function random(min = 0, max = 1) {
      if (min > max) {
        var hold = max;
        max = min;
        min = hold;
      }

      return Math.random() * (max - min) + min;
    }

    function maxOrbit(x, y) {
      var max = Math.max(x, y),
        diameter = Math.round(Math.sqrt(max * max + max * max));
      return diameter / 2;
    }

    var Star = function () {
      this.orbitRadius = Math.sqrt(random()) * maxOrbit(w, h);
      // this.radius = random(60, this.orbitRadius) / 20;
      this.radius = 5;
      this.orbitX = w / 2;
      this.orbitY = h / 2;
      this.timePassed = random(0, maxStars);
      this.speed = random(this.orbitRadius) / 900000;
      this.alpha = random(2, 10) / 10;

      count++;
      stars[count] = this;
    };

    Star.prototype.draw = function () {
      var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
        y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
        twinkle = random(10);

      if (twinkle === 1 && this.alpha > 0) {
        this.alpha -= 0.05;
      } else if (twinkle === 2 && this.alpha < 1) {
        this.alpha += 0.05;
      }

      ctx.globalAlpha = this.alpha;
      ctx.drawImage(
        canvas2,
        x - this.radius / 2,
        y - this.radius / 2,
        this.radius,
        this.radius
      );
      this.timePassed += this.speed;
    };

    for (var i = 0; i < maxStars; i++) {
      new Star();
    }

    function animation() {
      ctx.globalCompositeOperation = "source-over";
      ctx.globalAlpha = 0.8;
      // ctx.fillStyle = "hsla(" + hue + ", 64%, 6%, 1)";
      ctx.fillRect(0, 0, w, h);
      // ctx.clearRect(0,0,w,h);

      ctx.globalCompositeOperation = "lighter";
      for (var i = 1, l = stars.length; i < l; i++) {
        stars[i].draw();
      }

      window.requestAnimationFrame(animation);
    }

    animation();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed w-screen h-screen opacity-[0.8]"
      id="universe"
    ></canvas>
  );
};

export default Stars;
