import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap";
import { ReactComponent as IgIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TwtIcon } from "../../assets/icons/twitter.svg";
import "./Menu.css";

const Menu = ({ light }) => {
  const togglerRef = useRef();
  const menuRef = useRef();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline();
    if (checked) {
      tl.to(".menu", 0.5, {
        y: "0",
        ease: "Expo.easeIn",
      });

      tl.staggerFrom(
        ".menu-links > li  > a",
        1.5,
        {
          y: "2em",
          ease: "Expo.easeIn",
        },
        "0.25",
        "-=1.3"
      );
    } else {
      tl.to(".menu", 0.5, {
        y: "-100vh",
        ease: "Expo.easeIn",
      });
    }
  }, [checked]);

  useEffect(() => {
    const onscroll = (e) => {
      e.preventDefault();
      e.stopPropagation();

      return false;
    };
    menuRef.current.addEventListener("wheel", onscroll, { passive: false });

    menuRef.current.addEventListener("click", (e) => {
      if (e.target === menuRef.current) {
        setChecked(false);
      }
    });
  }, []);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <div className="relative z-[9999] w-[70px] flex justify-end" ref={togglerRef}>
        <label className={`toggle text-sm ${light && "light"}`}>
          <input type="checkbox" checked={checked} onChange={handleChange} />
          <div>
            <div>
              <span></span>
              <span></span>
            </div>
            <svg>
              <use xlinkHref="#path" />
            </svg>
            <svg>
              <use xlinkHref="#path" />
            </svg>
          </div>
        </label>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            id="path"
          >
            <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
          </symbol>
        </svg>
      </div>

      <div className="menu" ref={menuRef}>
        <ul className="menu-links">
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={"/about"}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/news"
            >
              News
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/contact"
            >
              Contact
            </NavLink>
          </li>
        </ul>

        <div className="self-end py-10 flex space-x-14">
          <a
            href={"https://instagram.com"}
            target="_blank"
            rel="noreferrer noopener"
            className="inline-block text-white hover:text-[#BF7E58]"
          >
            <IgIcon width={"25px"} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noreferrer"
            className="inline-block text-white hover:text-[#BF7E58]"
          >
            <LinIcon width={"25px"} />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noreferrer"
            className="inline-block text-white hover:text-[#BF7E58]"
          >
            <TwtIcon width={"25px"} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Menu;
