import Astronaut_1 from "../assets/personal/1_Astronaut.webp";
import Astronaut_1_1 from "../assets/personal/1.1.webp";
import Astronaut_1_2 from "../assets/personal/1.2.webp";
import Astronaut_1_3 from "../assets/personal/1.3.webp";
import Astronaut_1_4 from "../assets/personal/1.4.webp";
import Astronaut_1_5 from "../assets/personal/1.5.webp";
import Astronaut_1_6 from "../assets/personal/1.6.webp";
import Space_Architect_2 from "../assets/personal/2_Space_Architect.webp";
import Space_Architect_2_1 from "../assets/personal/2.1.webp";
import Space_Architect_2_2 from "../assets/personal/2.2.webp";
import Space_Architect_2_3 from "../assets/personal/2.3.webp";
import Space_Architect_2_4 from "../assets/personal/2.4.webp";
import Space_Architect_2_5 from "../assets/personal/2.5.webp";
import Space_Architect_2_6 from "../assets/personal/2.6.webp";
import Entrepreneur_3 from "../assets/personal/3_Entrepreneur.webp";
import Entrepreneur_3_1 from "../assets/personal/3.1.webp";
import Entrepreneur_3_2 from "../assets/personal/3.2.webp";
import Entrepreneur_3_3 from "../assets/personal/3.3.webp";
import Entrepreneur_3_4 from "../assets/personal/3.4.webp";
import Entrepreneur_3_5 from "../assets/personal/3.5.webp";
import Entrepreneur_3_6 from "../assets/personal/3.6.webp";
import Adventurer_4 from "../assets/personal/4_Adventurer1.webp";
import Adventurer_4_1 from "../assets/personal/4.1.webp";
import Adventurer_4_2 from "../assets/personal/4.2.webp";
import Adventurer_4_3 from "../assets/personal/4.3.webp";
import Adventurer_4_4 from "../assets/personal/4.4.webp";
import Adventurer_4_5 from "../assets/personal/4.5.webp";
import Adventurer_4_6 from "../assets/personal/4.6.webp";

const images = [
  [
    Adventurer_4_1,
    Adventurer_4_2,
    Adventurer_4_3,
    Adventurer_4_4,
    Adventurer_4_5,
    Adventurer_4_6,
    Astronaut_1,
  ],
  [
    Astronaut_1_1,
    Astronaut_1_2,
    Astronaut_1_3,
    Astronaut_1_4,
    Astronaut_1_5,
    Astronaut_1_6,
    Space_Architect_2,
  ],
  [
    Space_Architect_2_1,
    Space_Architect_2_2,
    Space_Architect_2_3,
    Space_Architect_2_4,
    Space_Architect_2_5,
    Space_Architect_2_6,
    Entrepreneur_3,
  ],
  [
    Entrepreneur_3_1,
    Entrepreneur_3_2,
    Entrepreneur_3_3,
    Entrepreneur_3_4,
    Entrepreneur_3_5,
    Entrepreneur_3_6,
    Adventurer_4,
  ],
  [],
];

export default images;
