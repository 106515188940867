import { useRef, useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setGlobeLoaded } from "../../redux/globe/globe.slice";
import gsap from "gsap";
import Globe from "./globe.lib";
import Stars from "./Stars";
import Loader from "../Loader/Loader";

export default function GlobeI({ projects }) {
  const dispatch = useDispatch();
  const globeRef = useRef();
  const containerRef = useRef();
  const switchRef = useRef();
  const loaderRef = createRef();
  const isMobile = window.innerWidth < 1030;
  const [initialLoadPreviewElapsed, setInitialLoadPreviewElapsed] =
    useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const { loaded: globeLoaded } = useSelector((state) => state.globe);
  const { activeProj, activePlanet, globeState } = useSelector(
    (state) => state.projects
  );

  useEffect(() => {
    if (!initialLoadPreviewElapsed) {
      // Always show loadeer for at least 3 seconds.
      setTimeout(() => {
        setInitialLoadPreviewElapsed(true);
      }, 3000);
    }

    if (globeLoaded && initialLoadPreviewElapsed) {
      // If globe has loaded and 8 seconds have elapsed, hide loader
      gsap.to(loaderRef.current, {
        autoAlpha: 0,
        duration: 1,
        ease: "expo.Out",
        onComplete: () => {
          setShowLoader(false);
        },
      });
    }
  }, [initialLoadPreviewElapsed, globeLoaded, loaderRef]);

  useEffect(() => {
    const globeProperties = {
      earth: [],
      mars: [],
      moon: [],
      asteroid: [],
    };
    projects.forEach((proj, idx) => {
      globeProperties[proj.planet.toLowerCase()].push({
        lon: proj.longitude,
        lat: proj.latitude,
        name: proj.location,
        planet: proj.planet.toLowerCase(),
      });
    });

    globeRef.current = new Globe(
      containerRef.current,
      globeState,
      activePlanet.toLowerCase(),
      globeProperties,
      isMobile
    );
    globeRef.current.init();

    return () => {
      globeRef.current.cleanup();
    };
  }, [projects]);

  useEffect(() => {
    const isMobile = window.innerWidth < 1030;
    globeRef.current.setIsMobile(isMobile);

    window.addEventListener("resize", () => {
      const isMobile = window.innerWidth < 1030;
      globeRef.current.setIsMobile(isMobile);
    });

    return () => {
      window.removeEventListener("resize", () => {
        const isMobile = window.innerWidth < 1030;
        globeRef.current.setIsMobile(isMobile);
      });
    };
  }, []);

  useEffect(() => {
    const switchProjectEvent = new CustomEvent("switch-project", {
      detail: {
        country: activeProj,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    switchRef.current.dispatchEvent(switchProjectEvent);
  }, [activeProj]);

  useEffect(() => {
    const switchPlanetEvent = new CustomEvent("switch-planet", {
      detail: {
        mode: activePlanet.toLowerCase(),
        adjustSlider: false,
      },
      bubbles: false,
      cancelable: true,
      composed: false,
    });

    const toggleMarker = new CustomEvent("toggle-markers", {
      detail: {
        mode: activePlanet.toLowerCase(),
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    switchRef.current.dispatchEvent(switchPlanetEvent);
    switchRef.current.dispatchEvent(toggleMarker);
  }, [activePlanet]);

  useEffect(() => {
    document.addEventListener("models-loaded", (e) => {
      dispatch(setGlobeLoaded(true));
    });
  }, [dispatch]);


  
  return (
    <div id="globe">
      <Stars />
      <div className="w-screen h-screen fixed" ref={containerRef}></div>

      {showLoader && <Loader ref={loaderRef} />}

      {/* Button used to fire all globe-related custom events */}
      <button id="switch" ref={switchRef} className="invisible"></button>
    </div>
  );
}
