import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import gsap from "gsap";
import Stars from "../Globe/Stars";

const globeImgUrls = {
  earth:
    "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/npzxaijada4qaf0p9ucp",
  moon: "https://res.cloudinary.com/dwb7cbf5c/image/upload/f_auto,q_auto/v1/globes/nhuia2tgqs9pnnkjwzzq",
  asteroid:
    "https://res.cloudinary.com/dwb7cbf5c/image/upload/f_auto,q_auto/v1/globes/rsyjqufktwcuhk0rn7oq",
  mars: "https://res.cloudinary.com/dwb7cbf5c/image/upload/f_auto,q_auto/v1/globes/vxxxcvafmjfhtbvgwdwa",
};

const GlobeMobile = () => {
  const { globeState, activePlanet } = useSelector((state) => state.projects);
  const [globeUrl, setGlobeUrl] = useState(globeImgUrls.earth);
  const [currentClass, setCurrentClass] = useState(
    "fixed max-w-none object-cover sm:bottom-[-45%] sm:left-[-20%] sm:w-[150vw] bottom-[-55vh] left-[-45%] w-[200vw]"
  );
  const imageRef = useRef();

  const updateGlobe = useCallback(
    (planet) => {
      const activeURL = globeImgUrls[planet.toLowerCase()];

      gsap.to(imageRef.current, {
        y: -200,
        autoAlpha: 0,
        duration: 0.5,
        ease: "expo",
        onComplete: () => {
          setGlobeUrl(activeURL);
          gsap.set(imageRef.current, {
            y: 0,
          });
        },
      });

      gsap.fromTo(
        imageRef.current,
        {
          y: 100,
          autoAlpha: 0,
          duration: 0.5,
          ease: "expo",
        },
        {
          y: 0,
          autoAlpha: 1,
        }
      );
    },
    [globeState]
  );

  useEffect(() => {
    if (globeState === 1) {
      setCurrentClass(
        "fixed max-w-none object-cover sm:top-0 top-[8%] left-[15%] w-[75%]"
      );
    }
  }, []); // Intentionally left empty as we only want to run this once

  useEffect(() => {
    if (globeState === 1) {
      const activeURL = globeImgUrls[activePlanet.toLowerCase()];
      setGlobeUrl(activeURL);
      updateGlobe(activePlanet);
    }
  }, [activePlanet, globeState, updateGlobe]);

  return (
    <div className="globe_mobile">
      <Stars />

      <div className="w-screen h-screen fixed">
        <img
          src={globeUrl}
          alt="globe-hd"
          id="globe-img"
          className={currentClass}
          ref={imageRef}
        />
      </div>
    </div>
  );
};

export default GlobeMobile;
