import { useState, useMemo } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ImageGallery = ({ images = [] }) => {
  const [index, setIndex] = useState(-1);
  const handleClick = (index, item) => {
    setIndex(index);
  };
  const slides = useMemo(
    () =>
      images.map(({ width, height, src }) => ({
        src,
        width: 1200,
        height: 800,
      })),
    [images]
  );

  return (
    <div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={250}
        margin={5}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default ImageGallery;
