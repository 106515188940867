import { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as LogoSlantWhite } from "../../assets/icons/logo-slant-white.svg";
import Menu from "../Menu/Menu";

const Navbar = ({ fixed, titleRef, light, className = "" }) => {
  const isMobile = window.innerWidth <= 1030;
  const nameRef = useRef();

  useEffect(() => {
    // Set opacity based on scroll position
    if (!isMobile) {
      const adjustPosition = (e) => {
        const scrollPos = window.scrollY;
        if (scrollPos <= 100) {
          const pct = scrollPos / 500;
          document.getElementById(
            "island"
          ).style.backgroundColor = `rgba(0,0,0,${pct.toFixed(2)})`;
        }
      };
      document.addEventListener("scroll", adjustPosition);

      return () => {
        document.removeEventListener("scroll", adjustPosition);
      };
    }
  }, [isMobile]);

  return (
    <div
      id="navbar"
      className={
        fixed ? `fixed z-10 w-full ${className}` : `${className} w-full z-30`
      }
    >
      <div
        className={`w-full top-0 flex items-start ${
          isMobile ? "justify-between" : "justify-center"
        } px-5 md:px-14 py-5 md:pt-8 pb-5 `}
      >
        {isMobile ? (
          <>
            <Link
              ref={nameRef}
              to="/"
              className={`hover:opacity-80 block text-xs tracking-distant font-medium ${
                light ? "text-[#22140C] hover:opacity-50" : ""
              }`}
            >
              <LogoSlantWhite width={30} height={30} />
            </Link>

            <Menu light={light} />
          </>
        ) : (
          <div
            id="island"
            className="space-x-6 text-center absolute w-fit px-2 rounded-lg"
          >
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [
                  isPending ? "" : "",
                  isActive
                    ? `inline-block text-sm font-medium px-4 py-1 bg-opacity-[16%] rounded-full transition-all ${
                        light ? "text-[#22140C] bg-black" : "bg-white"
                      }`
                    : `inline-block text-sm font-medium transition-all hover:text-primary ${
                        light ? "text-[#22140C]" : ""
                      }`,
                  isTransitioning ? "transitioning" : "",
                ].join(" ")
              }
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [
                  isPending ? "" : "",
                  isActive
                    ? `inline-block text-sm font-medium px-4 py-1 bg-opacity-[16%] rounded-full transition-all ${
                        light ? "text-[#22140C] bg-black" : "bg-white"
                      }`
                    : `inline-block text-sm font-medium transition-all hover:text-primary ${
                        light ? "text-[#22140C]" : ""
                      }`,
                  isTransitioning ? "transitioning" : "",
                ].join(" ")
              }
              to="/about"
            >
              About
            </NavLink>
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [
                  isPending ? "" : "",
                  isActive
                    ? `inline-block text-sm font-medium px-4 py-1 bg-opacity-[16%] rounded-full transition-all ${
                        light ? "text-[#22140C] bg-black" : "bg-white"
                      }`
                    : `inline-block text-sm font-medium transition-all hover:text-primary ${
                        light ? "text-[#22140C]" : ""
                      }`,
                  isTransitioning ? "transitioning" : "",
                ].join(" ")
              }
              to="/news"
            >
              News
            </NavLink>
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [
                  isPending ? "" : "",
                  isActive
                    ? `inline-block text-sm font-medium px-4 py-1 bg-opacity-[16%] rounded-full transition-all ${
                        light ? "text-[#22140C] bg-black" : "bg-white"
                      }`
                    : `inline-block text-sm font-medium transition-all hover:text-primary ${
                        light ? "text-[#22140C]" : ""
                      }`,
                  isTransitioning ? "transitioning" : "",
                ].join(" ")
              }
              to="/contact"
            >
              Contact
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
