import { createRef } from "react";

export const projects = [
  {
    id: 0,
    planet: "Earth",
    title: "Museum of the Future",
    type: "Museum",
    country: "UAE",
    year: "2015",
    buildingArea: "31,800 sqm",
    height: "82 m",
    role: "Team leader (Architecture facade) | Calligraphy 3d design | BIM management | Site supervision.",
    specialty: "Museum, F&B, Retail",
    tags: ["Earth", "Architecture"],
    location: "Dubai, United Arab Emirates",
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ko52laerakomr07virxo",
    ref: createRef(),
    persona: "architect",
    copy: {
      overview: {
        title: "Overview",
        body: "The Museum of the Future is primarily an exhibition space, which will exhibit innovative and futuristic ideologies, services and products, with other leasable spaces for Labs, F&B’s and an Auditorium.",
      },
      about: {
        title: "About",
        body: "The development occupies a prime location in Dubai at the northern corner of the Emirates Towers plot. The two major elements of the design are the vegetated hill-like Podium, and the futuristic metal clad upper iconic Museum building, which features intricate window designs in the form of Arabic calligraphy based on the Ruler of Dubai, His Highness Sheikh Mohammed’s poem. The poem is about the future Vision of Education, Healthcare, Smart Cities, Transportation, Government Services and Stainability for the region and the World. The podium will hold the majority of the services, MEP, parking, F&Bs, auditorium, and lobby, while the upper building will contain the majority of the exhibition spaces, as well as the administrative offices, press room, and majlis. The podium and museum building have heights of 19m and 63m respectively, resulting in an overall building height is 82m. There are a total of 204 car parking bays. The building has an overall floor area of 31,800 sqm.",
      },
      sustainability: {
        title: "Sustainability",
        body: "The Project is pursuing LEED Platinum, utilizing all new technology in the field of stainability and construction, my role was to coordinate all LEED points related to the facade system, plus designing a large solar-farm producing 12-Megawattts, producing all energy needed by the project and 2 projects adjacent to site.",
      },
    },
    images: [
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/xf29eu3ogxokkmcv1mzh",
        width: 180,
        height: 133,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/twhzjtmcnphphdx7fxbg",
        width: 550,
        height: 283,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/njuzamnhrafrrnhtmgi3",
        width: 500,
        height: 413,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/qoylwunshraqawpchkuk",
        width: 250,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/c6zdyncfx2je6ve09qte",
        width: 220,
        height: 213,
      },
    ],
    carouselItems: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/sjc0dqlawctmsgtaily7",
        title: "MAIN LOBBY",
        subtext:
          "Under the green mound, a 3-stories height futuristic space that encompasses the spirit of the museum. The calligraphy design was done me, I had to learn calligraphy for 6-months to be able to design a the exterior and interior calligraphy facade",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/p8eriahtgx0l1hxioggi",
        title: "7TH FLOOR MAIN EXHIBITION HALL",
        subtext:
          "The openings reflects the calligraphy cuts to the exterior facade, a fully coordinate structure with Structural diagrid and MEP connections",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/q6fxq7dz8rsyde1nl0tn",
        title: "THE ATRIUM",
        subtext:
          "Three panoramic lifts and a spiral staircase run through the entire building, serving the exhibition floors together with a massive 7x5 m freight elevator, that runs within the main concrete structural core",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/xu7qxwjx1enemzl1tvuk",
        title: "MAIN LOBBY",
        subtext:
          "It is the main entrance of the museum. A scenic double helix spiral staircase leads the visitors either to the auditorium or to the exhibition levels",
      },
    ],

    textRows: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/je1cksbbgmzcmvltah6y",
        title: "THE CONSTRUCTION SITE - RING BEAM",
        subtext:
          "A panoramic view from one of the cranes showing the cast of the “ring beam”, a 2.5x2.5m concrete beam collecting all the massive forces from the main steel diagrid structure of the museum.",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/sjkw6na7tvcahc9cs3om",
        title: "STEEL STRUCTURE MOCK-UPS",
        subtext:
          "Some of the mock-ups of the main typologies of nodes of the main steel diagrid structure",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/tpsyj8dmajmf1nyeoane",
        title: "FACADE MOCK-UPS",
        subtext:
          "Three of the many mock-ups of the facade, done to evaluate different conditions of how the complex facade is interacting with glass and steel structure",
      },
    ],
  },
  {
    id: 1,
    planet: "Earth",
    title: "Office of the Future",
    type: "Museum",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    latitude: 25.276987,
    longitude: 55.296249,
    year: "2016",
    buildingArea: "250 sqm",
    height: "8 m",
    role: "Team leader,  technology & 3D printing specialist",
    specialty: "Gallary, Offices.",
    tags: ["Earth", "Architecture", "Robotics"],
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/hrsadrwxlmtdikpxp0yb",
    ref: createRef(),
    persona: "architect",
    copy: {
      overview: {
        title: "Overview",
        body: "The Office of the Future is world’s first fully functional and permanently occupied 3D printed building in the world, as they were awarded the Guieness world record for this title. The Office of the Future building is currently the temporary home for the Dubai Future Foundation as well as an exhibition space and incubator for future emerging technologies in the region. The entire structure was printed in concrete using an additive manufacturing technique.",
      },
      about: {
        title: "About",
        body: "The entire structure of the building was manufactured using an additive concrete ‘printing’ technique using a 3D printer 20 feet high, 120 feet long and 40 feet wide. The printer features an automated robotic arm to implement the printing process which lasted 17 days and was installed on site in two days. Subsequent work on the building services, interiors, and landscape took approximately three months. As a result of this innovative construction technique, the labor cost was cut by more than 50% compared to conventional buildings of similar size, and wastage on site was minimized which helped to reduce the overall environmental footprint of the project.",
      },
      sustainability: {
        title: "Guiness Record",
        body: "Involving the most cutting edge and innovative projects, we made use of a super insulated cladding system, fabricated using computer controlled manufacturing techniques, to form the unique and complex geometry of the building envelope. This project has won Guinness World Record in 2020 as the first 3D printed commercial project in the world.",
      },
    },
    images: [
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/let3g7bexcsp75yje4x6",
        width: 180,
        height: 133,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/g4kc6oipjbeyrrscbfs5",
        width: 550,
        height: 283,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ha9upujvkmd907f4md2v",
        width: 500,
        height: 413,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/tdttyxse5sph9u2nyedd",
        width: 250,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/wsfad4wjvt069gcwuxvq",
        width: 220,
        height: 213,
      },
    ],
    carouselItems: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/j801xn6a6u51ifsxlw6o",
        title: "PORTALS OF LIGHT",
        subtext:
          "The arrangement shift of the cassets was with the intention to create a various pockets to let natural light reach the internal spaces",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/fxi56dtnkivnofqdvsng",
        title: "STAINLESS STEEL SCULPTURE",
        subtext:
          "3D modeled and carved Styrofoam, coated with Stainless steel layer to have a reflective surface",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/vbdo1lxkqhchgqeo8tem",
        title: "MEETING ROOM",
        subtext:
          "The space was create to give the feeling of a jungle, surrounded by greenery from all sides and inside",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/hrsadrwxlmtdikpxp0yb",
        title: "THE FRONT ELEVATION",
        subtext:
          "The full design is comprised of modular structural units, manufactured by Winsun Inc in China. The portal represents one vertical structural casset while every other space is created by a series of horizontal cassets",
      },
    ],
    textRows: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/mqipujjbsatora4e52nk",
        title: "FACADE EXPERIMENTATIONS",
        subtext:
          "The facade design intention was to bio-mimic corals, nature best 3D additive manufacturers. The experimentations started with inputting the 3D printer limitation parameters, then iterating the results to have an optimum final product",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/bktl4owgefscqgmp2sci",
        title: "SUSTAINABLE ASPECTS",
        subtext:
          "The project is oriented to maximize visibility and natural light, but to shade the inside through projecting overhangs above the windows, optimized to fully shade the glazing from direct sun. This approach minimize direct solar heating and reduces the need for air conditioning and lighting. LED lighting, responsive building systems, reduced irrigation landscaping, and low energy air conditioning serve to further reduce the building’s energy footprint",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/gwgm9mcywzuyc7xn6nwt",
        title: "EXTRA GREEN MEASUREMENTS",
        subtext:
          "All existing trees on site were preserved, the building masses were carefully places in between. The design of the building is intended to improve energy efficiency in the hot climate of UAE, which commonly experiences temperatures that rises above 45 degrees Celsius (113 F) and makes use of 800mm thick insulating cladding",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/wwzsgebttumskzbejkz7",
        title: "STRUCTURE CASSETS",
        subtext:
          "3D manufactured in China, shipped all the way to Dubai, United Arab Emirates: Each structural casset went through many load testing to analyze it’s performance and making sure it meets the Municipality requirements",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ei72aureo2wfpk9lu3ff",
        title: "FACADE MOCK-UPS",
        subtext:
          "One of many 1:1 scale mock-ups testing the capability of the 3D printer, to validate the design proposed. After various iterations the client chosen to exclude the Coral-inspired facade and keep the clean form as a statement",
      },
    ],
  },
  {
    id: 2,
    planet: "Earth",
    title: "Sheybarah Reef Resort",
    type: "Project",
    country: "Saudi Arabia",
    location: "The Red Sea, Saudi Arabia",
    latitude: 20.385824,
    longitude: 38.122559,
    year: "2019",
    buildingArea: "30,000 sqm",
    height: "10 m",
    role: "Conceptual designer & Team leader",
    specialty: "Ultra-luxury resort",
    tags: ["Earth", "Architecture"],
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/yxbvvpbchbq1mqmml0bv",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 3,
    planet: "Earth",
    title: "Marsa Al Arab",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2019",
    buildingArea: "65,000 sqm",
    height: "50 m",
    role: "Conceptual designer",
    specialty: "Hotel, Service apartment & F&B",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/udhd3k4kx8jh7bjttald",

    ref: createRef(),
    persona: "architect",
    copy: {
      overview: {
        title: "Overview",
        body: "The “Marsa Al Arab” project is located on the coast line in Dubai. The plot is locked between a marina on its right and other private properties on its left, leaving a very long and small footprint for the construction to happen.",
      },
      about: {
        title: "About",
        body: "The brief requests that the building should not “compete” with the Burj Al Arab landmark and in fact it should celebrate it.The building should also feature large balconies and be green-field. In addition to this, the client wants vehicular access to be guaranteed to both the marina and the last portion of the development. By separating the two residential and hotel program, the initial volume is divided in two parts, in order to guarantee that views of the landmark from the public beach are maintained In order to make up the area which has been lost in the splitting process, the two separate buildings are merged in the middle. This will also create a virtual “frame” of the Burj Al Arab, which then gets celebrated. The shape is then optimized and sub- divided in floor. Thanks to the form of the building, each apartment and hotel room at the top can feature a large terrace overlooking the sea.",
      },
      sustainability: {
        title: "Features",
        body: "By selectively pulling the facade in strategic points, additional pocket area created throughout the building. These pockets can accommodate trees, in order for each of the apartment and hotel room to have access to an elevated private garden. By both creating overhangs and adding trees, the heat gain from the sun onto the facade will be reduced, enhancing both the indoor and outdoor user experience.",
      },
    },
    carouselItems: [
      {
        image: "",
        title: "MAIN DROP-OFF AND MARINA",
        subtext:
          "A void was created at the arrival drop-off zone, giving the guests a framed view of the famous Burj Al Arab hotel. Furthermore, the Marina have a long stripe of retail stores on the lower floor, overlooking the Marine yard.",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ownhhnhn1bvfeuvlbh3q",
        title: "VIEWING EXPERIENCE",
        subtext:
          "All guests in the hotel will have a private but yet open view to the Burj Al Arab hotel and open sea, every hotel guest room have a long balcony with this vista",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/pavhbgy2upr7lf7iw5nj",
        title: "REVETMENT VILLA",
        subtext:
          "Nine very luxurious villas were added to the project, each overflowing on the revetment wall and overhanging on water. Three contemporary prototypes were designed, buying clients get to chose which design they want to build.",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ownhhnhn1bvfeuvlbh3q",
        title: "VIEWING EXPERIENCE",
        subtext:
          "All guests in the hotel will have a private but yet open view to the Burj Al Arab hotel and open sea, every hotel guest room have a long balcony with this vista",
      },
    ],
    images: [
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/edip12gfqb8ejyttzhez",
        width: 180,
        height: 133,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/wmte95mrvyfuy6idxbap",
        width: 550,
        height: 283,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/kxysjpflkztwi3nbnq3a",
        width: 500,
        height: 413,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/rfrbsfjlzm15wpzkximd",
        width: 250,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/nqn3bvmfxpcypkdnzm80",
        width: 220,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/hj6qqzadcx1gz9urx951",
        width: 220,
        height: 213,
      },
    ],
  },
  {
    id: 4,
    planet: "Earth",
    title: "Luxury Palace",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2018",
    buildingArea: "11,000 sqm",
    height: "20 m",
    role: "Conceptual designer & Team leader",
    specialty: "Residential",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/v1711637457/hatem%3Dwebsite/nelvpsajxwplw6zb84wp.webp",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 5,
    planet: "Earth",
    title: "Art Gallary",
    country: "UAE",
    location: "Sharjah, United Arab Emirates",
    year: "2020",
    buildingArea: "",
    height: "",
    role: "",
    specialty: "",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/k4layj62uv7hifipspff",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 6,
    planet: "Earth",
    title: "SRG Tower",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2017",
    buildingArea: "113,500 sqm",
    height: "465 m",
    role: "Conceptual designer & Team leader",
    specialty: "Residential tower",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/v1711637455/hatem%3Dwebsite/oi9cisnjrfg1budsqh0j.jpg",
    ref: createRef(),
    persona: "entrepreneur",
    copy: {
      overview: {
        title: "Overview",
        body: "The development occupies a prime location in Dubai along Sheikh Zayed Road, in the proximity of the Dubai Opera. The tower is a residential tower with an adjacent car park building; the top of tower will feature two wind turbines with a viewing platform above, which will offer uninterrupted views of the Burj Khalifa, Dubai Opera, Downtown as well as on the west, the Creek extension, The World islands and Arabian Gulf..",
      },
      about: {
        title: "About",
        body: "The three design are the external Glassfibre Reinforced Concrete (GRC) diagrid, the innovative and iconic two wind turbines on the top of the building and the three skygardens which offer green communal spaces within the tower and help reduce the wind load on the facade. The Client’s brief was to create a highly efficient, highly engineered iconic super-high-rise tower that resonates efficiency, innovation and sustainability and to create a new benchmark in future high-rise towers. The SRG Tower was also to be an instantly recognizable form that will be on Dubai’s postcard, magazines and future movies as being a signature and timeless structure that defines a new course. The Pre Concept Design was a culmination of Architecture, Structures, Wind Engineers, Sustainability, Façade Engineers all at the same time. Ultimately the Mega Diagrid Structure was selected as it creates an instantly recognizable architectural expression and not only makes the building unique and iconic, but is also highly efficient in resolving the lateral wind loads, slowing the wind across the building therefore reducing the negative pressures behind the building. It also reduced the weight of the building and the foundations by up to 22%.",
      },
      sustainability: {
        title: "Sustainability",
        body: "The tower is LEED Platinum targeted and is the 1st of its kind in the GCC. It also aims to be the tallest residential LEED Platinum certified building in the world.",
      },
    },
    portraits: [
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/drdejjdu7v9isgt88rds",
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/lakli427fv2oyocm8d41",
    ],
    carouselItems: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/enwaaf8pz8xbp2qn7rp7",
        title: "THE OBSERVATION DECK",
        subtext:
          "The building will feature the 6th tallest observation deck in the world, which will offer uninterrupted views of the Burj Khalifa, Dubai Opera, Downtown as well as on the west, the Creek Extension, The World islands and Arabian Gulf",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/luhuvqgrgku6dtpbz0tz",
        title: "ENTRANCE LOBBY",
        subtext:
          "The lobby is a 4 storey high volume, surrounded by a clear glass facade to maximise daylight",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/vozdgwnozivxqhi8ijlc",
        title: "BIM",
        subtext:
          "The entire project has been developed in Revit, from the Concept to the Construction Drawings. All disciplines including Structures, MEP, AV/ IT and Landscape have worked in Revit and have been coordinated through fortunately sessions of clash detection in Navisworks.",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/muwn6edhv6wefwqefagy",
        title: "Wind turbine - Viewing Platform",
        subtext:
          "On a height around 400m sits two wind turbines and a viewing platform, with massive wind load coming from the North sea side. This required a heavily coordinated and engineered approach to ensure the stability of the tower crown",
      },
    ],
  },
  {
    id: 7,
    planet: "Earth",
    title: "NEMO Science Museum",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2017",
    buildingArea: "15,000 sqm",
    height: "22 m",
    role: "Conceptual designer",
    specialty: "Museum",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/uil2wrrpzhu0uhsvakod",
    ref: createRef(),
    persona: "entrepreneur",
    copy: {
      overview: {
        title: "Overview",
        body: "This project occupies a prime location in Abu Dhabi, the capital city of United Arab Emirates. It is the first science museum the city will have, in an effort by the minister of culture and education to enrich the city cultural keystones.",
      },
      about: {
        title: "About",
        body: "The NEMO Science Museum goal is to contain a richness of science experiences - around each corner, an expected journey awaits. Three thrilling Discovery Expeditions form the core of the science centre. Around it, a variety of other experiences can be found: from flow exhibitions with interactive or impressive pavilions, mediated labs and makers spaces, to iconic studio and science dome ensures that there is always “something for everyone” and that local visitors will always have a reason to return. The project is aspiring to attract annual visitors of 750,000, the average daily would be 2050 visitors based on 365 days per year.",
      },
      sustainability: {
        title: "Inspiration",
        body: "The proposed design is inspired by the DNA, The basic block of life of life on our planet Earth as we know it. Its the oldest record of history, carrying over billions of years. Random changes in the genes defines every creature, and this what allowed everyone to adapt and evolve to various environments and times. Humans share 99% of the DNA sequence, the difference in 1% defines who we are as individuals.",
      },
    },
    carouselItems: [
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/s1g0cscimcar0dt31x4y",
        title: "MAIN ROAD ELEVATION",
        subtext:
          "The concept of DNA strings wrapping is very clear in the long elevation, while continue form lifts upward at the entrance side, creating a shaded plaza and portal to host people through. It also reveals a spherical stainless steel projection Planetarium, which also works as a structural anchor for the cantilevered roof",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/s1g0cscimcar0dt31x4y",
        title: "SCIENCE PLAZA ELEVATION",
        subtext:
          "The back elevation have a different play while still using the same concept form, it create more solid part at the multi-purpose hall while opening up at the exhibition area which needs indirect za have various sculptures and softscapes, to attract people to walk and sit around it, and for kids coming in school groups to have some activities in it.",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/cunnletqdleumfyrkg1m",
        title: "Main Entrance & PLANETARIUM",
        subtext:
          "The entrance is emphasized by creating a void under the twisted roof, providing a shaded plaza for the visitors to stand and appreciate the spherical form of the planetarium",
      },
      {
        image:
          "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/jft2qd5hpyfiac3meaq0",
        title: "SOUVENIR STORE",
        subtext:
          "Located on the East-side, the store is at the end of guest tour. A place where you get to either buy a souvenir or sit and enjoy a cup of coffee while looking at the science models all around you.",
      },
    ],
    images: [
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/ovpg0lg29tzvanpjmw2b",
        width: 180,
        height: 133,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/eqzshbfsrbtvixgtazg9",
        width: 550,
        height: 283,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/cbr64ub0qckelfh6kd6o",
        width: 500,
        height: 413,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/nugqyp4jgzmahhurf6vk",
        width: 250,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/v4znq35jvfje6eyf68j7",
        width: 220,
        height: 213,
      },
    ],
  },
  {
    id: 8,
    planet: "Earth",
    title: "HQ Building",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2015",
    buildingArea: "30,000 sqm",
    height: "30 m",
    role: "Conceptual designer & visualizer",
    specialty: "Office & Retail",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/rzisnkv6nkitmep22qpf",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 9,
    planet: "Earth",
    title: "Dibba Beach Resort",
    country: "UAE",
    location: "Dubai, United Arab Emirates",
    year: "2018",
    buildingArea: "21,700 sqm",
    height: "40 m",
    role: "Conceptual designer & team leader",
    specialty: "5-stars resort & F&B",
    tags: ["Earth", "Architecture"],
    latitude: 25.276987,
    longitude: 55.296249,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/cqsguhgsuotf3xjdlels",
    ref: createRef(),
    persona: "adventurer",
  },
  {
    id: 10,
    planet: "Earth",
    title: "NEOM Mountain",
    country: "Saudi Arabia",
    location: "NEOM, Saudi Arabia",
    year: "2021",
    buildingArea: "",
    height: "20m",
    role: "",
    specialty: "",
    tags: ["Earth", "Architecture"],
    latitude: 24.774265,
    longitude: 46.738586,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/imyfa6bjsjcmszqqw9fy",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 11,
    planet: "Earth",
    title: "Sagamore hotel",
    country: "United States",
    location: "Miami, United States",
    year: "2021",
    buildingArea: "",
    height: "28m",
    role: "Architect",
    specialty: "Hotel renovation",
    tags: ["Earth", "Architecture"],
    latitude: 25.7617,
    longitude: -80.191788,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/frkoawt6yd6hhk5urqts",
    ref: createRef(),
    persona: "architect",
  },
  {
    id: 12,
    planet: "Earth",
    title: "RA Sanctuaries",
    country: "Canada",
    location: "Toronto, Canada",
    year: "2022",
    buildingArea: "",
    height: "",
    role: "Head of Technology & Revenue",
    specialty: "Start-up | Construction & Software Technology",
    tags: ["Earth", "Architecture", "Entrepreneurship", "Robotics"],
    latitude: 43.65107,
    longitude: -79.347015,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/vfw2j80gollaqf93dxrm",
    ref: createRef(),
    persona: "adventurer",
  },
  {
    id: 13,
    planet: "Earth",
    title: "Space Roasters",
    country: "United States",
    location: "California, United States",
    year: "2017",
    buildingArea: "",
    height: "",
    role: "Co-founder",
    specialty: "Start-up | Space Technology",
    tags: ["Entrepreneurship", "Patent"],
    latitude: 36.778259,
    longitude: -119.417931,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/v1711637456/hatem%3Dwebsite/czhz7vf2uwamxcg52n1n.webp",
    ref: createRef(),
    persona: "entrepreneur",
  },
  {
    id: 14,
    planet: "Earth",
    title: "Dragonfly",
    country: "France",
    location: "Strasbourg, France",
    year: "2017",
    buildingArea: "",
    height: "",
    role: "Co-author",
    specialty: "Research paper",
    tags: ["Research"],
    latitude: 48.580002,
    longitude: 7.75,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/phkkz8tfcd8sazeiniur",
    ref: createRef(),
    persona: "adventurer",
  },
  {
    id: 15,
    planet: "Earth",
    title: "Molecular Robotics",
    country: "Japan",
    location: "Sendai, Japan",
    year: "2017",
    buildingArea: "",
    height: "",
    role: "Author",
    specialty: "Research paper",
    tags: ["Research", "Robotics"],
    latitude: 38.268223,
    longitude: 140.869415,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/cwzslwnwlee6noru3nvw",

    ref: createRef(),
    persona: "entrepreneur",
  },
  {
    id: 16,
    planet: "Moon",
    title: "Self-sufficient colony on the Moon surface",
    location: "South Pole",
    year: "2013",
    buildingArea: "infinite",
    height: "15m",
    role: "Architect",
    specialty: "Design Project",
    tags: ["Research", "Space"],
    latitude: 90.0,
    longitude: 45.0,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/whdxiwn8nqxq2ezurv4k",
    ref: createRef(),
    persona: "adventurer",
  },
  {
    id: 17,
    planet: "Moon",
    title: "Construction Robot",
    location: "South Pole",
    year: "2017",
    buildingArea: "",
    height: "",
    role: "Conceptual Designer",
    specialty: "Design & Publication",
    tags: ["Research", "Robotics"],
    latitude: 90.0,
    longitude: 45.0,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/v1711637455/hatem%3Dwebsite/vcqvkklaq0rburqvkhgg.webp",

    ref: createRef(),
    persona: "astronaut",
  },
  {
    id: 18,
    planet: "Moon",
    title: "Lunar Dimensions book",
    location: "Center",
    year: "2013",
    buildingArea: "",
    height: "",
    role: "Author",
    specialty: "Publication",
    tags: ["Research", "Space", "Architecture"],
    latitude: 46.6033,
    longitude: 1.8883,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/uaxxaieeclpjo6vjveyz",
    ref: createRef(),
    persona: "astronaut",
  },
  {
    id: 19,
    planet: "Mars",
    title: "Construction Robot",
    location: "North Pole",
    year: "2013",
    buildingArea: "",
    height: "",
    role: "Conceptual Designer",
    specialty: "Design & Publication",
    tags: ["Research", "Robotics"],
    latitude: 90,
    longitude: 1.8883,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/jdyvpwzvuvooo1i1gqdk",

    ref: createRef(),
    persona: "astronaut",
  },
  {
    id: 20,
    planet: "Asteroid",
    title: "Gripper Climbing Robot",
    location: "Centre",
    year: "2017",
    buildingArea: "",
    height: "",
    role: "Gripping Mechanism designer & engineer",
    specialty: "Design & Publication",
    tags: ["Research", "Robotics"],
    latitude: 46.6033,
    longitude: 1.8883,
    image:
      "https://res.cloudinary.com/dqh4lfhcr/image/upload/f_auto,q_auto/v1/hatem%3Dwebsite/loye2jzkqvjs0gqwbfma",
    ref: createRef(),
    persona: "astronaut",
  },
];
