import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useProjects from "../../hooks/useProjects";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobeState,
  setActiveProjectImg,
  setActiveProjectId,
  setActivePlanet,
} from "../../redux/projects/projects.slice";
import { ReactComponent as ArrowIcon } from "../../assets/icons/active-arror.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/active-down-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow.svg";
import ScrollImg from "../../assets/scroll.png";
import { gsap } from "gsap";
import { ScrollTrigger, Flip, ScrollToPlugin, CustomEase } from "gsap/all";
import {
  positionActiveArrowDesktop,
  positionActiveArrowMobile,
} from "../../utils";
import Globe from "../../components/Globe/Globe";
import Navbar from "../../components/Navbar/Navbar";
import Slider from "../../components/Slider/Slider";
import MobileCarousel from "../../components/MobileCarousel/MobileCarousel";
import GlobeMobile from "../../components/GlobeMobile/GlobeMobile";
import "./Home.css";

gsap.registerPlugin(Flip, ScrollTrigger, ScrollToPlugin);

const Home = () => {
  const isMobile = window.innerWidth < 1030;
  const tlRef = useRef;
  const navigate = useNavigate();
  const { projects } = useProjects();
  const { globeState, activeIdx, activePlanet } = useSelector(
    (state) => state.projects
  ); // 0: Default state, 1: Viewing Projects
  const [activeProject, setActiveProject] = useState(projects[activeIdx]);
  const dispatch = useDispatch();
  const titleRef = useRef();
  const descRef = useRef();
  const btnRef = useRef();
  const navTitleRef = useRef();
  const infoRef = useRef();
  const statsRef = useRef();
  const titlesRef = useRef();
  const cardsRef = useRef();
  const sliderRef = useRef(null);
  const planetNavRef = useRef();
  const planetArrowRef = useRef();

  CustomEase.create("slider", "0.70, 0.00, 0.3, 1.00");

  useEffect(() => {
    const handleScroll = (e) => {
      if (globeState === 0 && window.scrollY > 5) {
        const changeState = new CustomEvent("change-globe-state", {
          detail: {},
          bubbles: true,
          cancelable: true,
          composed: false,
        });
        document.querySelector("#home").dispatchEvent(changeState);
        dispatch(setGlobeState(1));

        if (isMobile) {
          const globeImg = document.querySelector("#globe-img");

          gsap.to(globeImg, {
            duration: 2,
            ease: "move",
            top: window.innerWidth < 500 ? "5%" : 0,
            left: 0,
            width: "100%",
          });
        }
      }
    };

    document.addEventListener("scroll", handleScroll);
    window.history.scrollRestoration = "manual";

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, globeState, isMobile]);

  useEffect(() => {
    const title = titleRef.current;
    const desc = descRef.current;

    if (globeState === 1) {
      infoRef.current.style.display = "none";
    }

    const tl = gsap.timeline();

    tl.from(title, {
      duration: 0.5,
      autoAlpha: 0,
      y: 50,
    });
    tl.from(desc, {
      duration: 0.3,
      autoAlpha: 0,
      y: 50,
    });
  }, []);

  useEffect(() => {
    tlRef.current = new gsap.timeline({
      ease: "power2.out",
      paused: true,
    });
    const tl = tlRef.current;

    tl.to(infoRef.current, {
      duration: 1,
      y: -700,
      autoAlpha: 0,
    });

    tl.from(cardsRef.current, {
      autoAlpha: 0,
      y: 50,
      duration: 1,
      delay: 1,
    });

    tl.to(navTitleRef.current, {
      duration: 0.5,
      autoAlpha: 1,
    });

    tl.to(statsRef.current, {
      duration: 0.5,
      autoAlpha: 0.5,
    });

    tl.to(planetNavRef.current, {
      duration: 0.5,
      autoAlpha: 1,
    });

    tl.to(titlesRef.current, {
      duration: 0.5,
      // y: 500,
      autoAlpha: 1,
    });
  }, [tlRef]);

  useEffect(() => {
    if (globeState === 1) {
      tlRef.current.play();
    }
  }, [globeState, tlRef]);

  useEffect(() => {
    const activeProject = projects[activeIdx];
    if (activeProject) {
      setActiveProject(activeProject);
      dispatch(setActiveProjectId(activeProject.id));
    }
  }, [activeIdx, projects, dispatch]);

  useEffect(() => {
    if (isMobile) {
      const index = projects.findIndex(
        (proj) => proj.planet.toLowerCase() === activePlanet.toLowerCase()
      );
      if (index !== -1) {
        // sliderRef.current.slickPause();
        // sliderRef.current.slickGoTo(index);
        // sliderRef.current.slickPlay();
      }
      positionActiveArrowMobile(activePlanet, planetArrowRef);
    } else {
      positionActiveArrowDesktop(activePlanet, planetArrowRef);
    }
  }, [activePlanet, isMobile, projects]);

  const onViewProjects = () => {
    CustomEase.create("move", "0.60, 0.00, 0.40, 1.00");
    dispatch(setGlobeState(1));

    if (isMobile) {
      const globeImg = document.querySelector("#globe-img");

      gsap.to(globeImg, {
        duration: 2,
        ease: "move",
        top: window.innerWidth < 500 ? "8%" : 0,
        left: "15%",
        width: "75%",
      });
    }
  };

  const onProjectClickMobile = useCallback(() => {
    // sliderRef.current.slickPause();
    const project = projects[activeIdx];

    // set project image as active (for seamless transition)
    dispatch(setActiveProjectImg(project.image.url));
    navigate(`/projects/${project.documentId}`);
  }, [dispatch, activeIdx, navigate, projects]);

  const onSwitchPlanet = (planet) => {
    dispatch(setActivePlanet(planet));
  };

  return (
    <div id="home" className="relative min-h-[500vh]">
      <Navbar fixed titleRef={navTitleRef} />

      <div id="content">
        <div
          id="info"
          ref={infoRef}
          className="fixed w-full flex justify-center text-center md:px-32 mt-36 z-[5]"
        >
          <div className="w-full md:max-w-lg px-5 md:px-0">
            <h1 className="title-large mb-5 md:mb-10" ref={titleRef}>
              Hi, I’m Hatem
            </h1>
            <p
              className="body-base text-white md:text-[#BBBBBB] mb-5 md:mb-10"
              ref={descRef}
            >
              An{" "}
              <span className="body-base-strong">
                award winning space architect
              </span>{" "}
              and an aspiring astronaut who has designed and built some of the
              world’s best architecture masterpieces.
            </p>

            <button
              ref={btnRef}
              id="viewProjects"
              className="inline-block border border-white px-10 py-3 text-white rounded-sm body-base-strong hover:bg-white hover:text-black hover:opacity-80"
              onClick={onViewProjects}
            >
              View projects
            </button>
          </div>
        </div>

        {!isMobile && <Globe projects={projects} />}

        {isMobile && <GlobeMobile />}

        {isMobile ? (
          <MobileCarousel
            containerRef={cardsRef}
            sliderRef={sliderRef}
            onProjectClick={onProjectClickMobile}
            projects={projects}
          />
        ) : (
          <Slider containerRef={cardsRef} projects={projects} />
        )}

        <div
          id="planetButtons"
          className="fixed invisible md:top-[35%] top-[50%] md:left-[100px] w-full md:w-auto"
          ref={planetNavRef}
        >
          <div className="relative  py-2 px-5 flex justify-center md:justify-start md:flex-col md:gap-x-0 gap-x-5 md:gap-y-6">
            <span ref={planetArrowRef}>
              {isMobile ? <ArrowDownIcon /> : <ArrowIcon />}
            </span>
            <div className="p-2">
              <button
                onClick={() => onSwitchPlanet("Earth")}
                className={`text-xs text-white uppercase font-semibold hover:opacity-100 inline-block p-0 m-0 outline-none ${
                  activePlanet?.toLowerCase() === "earth"
                    ? "opacity-100"
                    : "opacity-50 focus:opacity-50"
                }`}
              >
                Earth
              </button>
            </div>
            <div className="p-2">
              <button
                onClick={() => onSwitchPlanet("Moon")}
                className={`text-xs text-white uppercase font-semibold hover:opacity-100 inline-block p-0 m-0 outline-none ${
                  activePlanet?.toLowerCase() === "moon"
                    ? "opacity-100"
                    : "opacity-50"
                }`}
              >
                Moon
              </button>
            </div>
            <div className="p-2">
              <button
                onClick={() => onSwitchPlanet("Mars")}
                className={`text-xs text-white uppercase font-semibold hover:opacity-100 inline-block p-0 m-0 outline-none ${
                  activePlanet?.toLowerCase() === "mars"
                    ? "opacity-100"
                    : "opacity-50"
                }`}
              >
                Mars
              </button>
            </div>
            <div className="p-2">
              <button
                onClick={() => onSwitchPlanet("Asteroid")}
                className={`text-xs text-white uppercase font-semibold hover:opacity-100 inline-block p-0 m-0 outline-none ${
                  activePlanet?.toLowerCase() === "asteroid"
                    ? "opacity-100"
                    : "opacity-50"
                }`}
              >
                Asteroid
              </button>
            </div>
          </div>
        </div>

        <div
          id="titles"
          className={`md:hidden fixed invisible py-2 px-5 w-full bottom-[10px] md:px-14 flex items-center`}
          ref={titlesRef}
          role="button"
          onClick={onProjectClickMobile}
        >
          <div className="flex-1">
            <p
              id="projectTitle"
              className="title-medium flex w-full justify-between items-center mb-2 md:mb-3 hover:opacity-70 cursor-pointer"
            >
              {activeProject?.title}
            </p>
            <p className="text-[#aaa] body-base mt-2 md:text-left">
              {activeProject?.specialty}
              {activeProject?.specialty && activeProject?.location && " | "}
              {activeProject?.location}
            </p>
          </div>

          <span>
            <ArrowRightIcon width={"35px"} className="inline-block ml-4" />
          </span>
        </div>
      </div>

      {!isMobile && globeState === 1 && (
        <img
          src={ScrollImg}
          alt="scroll indicator"
          className="fixed right-20 top-[40vh]"
        />
      )}
    </div>
  );
};

export default Home;
