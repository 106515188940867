import { useState, useRef, useEffect } from "react";
import Stars from "../../components/Globe/Stars";
import Navbar from "../../components/Navbar/Navbar";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as Rocket } from "../../assets/icons/rocket.svg";
import { ReactComponent as Rocket1 } from "../../assets/icons/rocket-1.svg";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { gsap } from "gsap";
import emailJS from "@emailjs/browser";
import "./Contact.css";

const Contact = () => {
  const w = window.innerWidth;
  const formRef = useRef();
  const rocketRef = useRef();
  const lineRef = useRef();
  const tlRef = useRef();
  const successRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formItems, setFormItems] = useState({
    name: "",
    email: "",
    message: "",
  });

  const pageRef = useRef(null);
  const navTitleRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    // Fade in animation for the entire page
    tl.fromTo(
      pageRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1.5, ease: "expo.inOut" }
    );

    tl.to(navTitleRef.current, {
      duration: 0.5,
      autoAlpha: 1,
    });

    // Existing animation
    tl.to("#universe", {
      autoAlpha: 1,
    });
  }, []);

  useEffect(() => {
    tlRef.current = gsap.timeline({ paused: true });
    const tl = tlRef.current;

    tl.to(formRef.current, {
      autoAlpha: 0,
      duration: 1,
      ease: "expo.out",
    });

    tl.to(
      rocketRef.current,
      {
        translateX: "100vw",
        duration: 2,
      },
      "rocket"
    );

    tl.to(
      lineRef.current,
      {
        attr: {
          x2: w,
        },
        duration: 2,
      },
      "rocket"
    );

    tl.to(successRef.current, {
      autoAlpha: 1,
    });
  }, [w]);

  const animateSuccess = () => {
    tlRef.current.play();
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormItems({
      ...formItems,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { name, email, message } = formItems;

    if (!name || !email || !message) {
      setIsLoading(false);
      toast.error("Please fill out all fields!");
      return;
    }

    const serviceId = "service_wkgi3lt";
    const templateId = "template_ma6ap4t";
    const publicKey = "yg99NkprXCtB1ICHu";

    const templateParams = {
      from_name: name,
      from_email: email,
      message,
    };

    try {
      await emailJS.send(serviceId, templateId, templateParams, publicKey);
      setFormItems({
        name: "",
        email: "",
        message: "",
      });

      setIsLoading(false);
      animateSuccess();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toast.error("Error sending message!");
    }
  };

  const easterEgg = () => {
    gsap.to(rocketRef.current, {
      translateX: "110vw",
      duration: 2,
      onComplete: () => {
        gsap.fromTo(
          rocketRef.current,
          {
            x: -20,
          },
          {
            x: 0,
          }
        );
      },
    });
  };

  const onResend = () => {
    tlRef.current.reverse();
  };

  return (
    <div className="max-h-screen overflow-hidden relative">
      <Stars />
      <Navbar fixed ref={navTitleRef} />

      <div className="h-screen px-4" ref={pageRef}>
        <div className="h-full flex items-center justify-between">
          <div className="min-h-screen md:w-[60vw] hidden md:flex flex-col justify-center">
            <svg viewBox="0 0 120 120" id="ring">
              <circle
                cx="60"
                cy="60"
                r="60"
                stroke="#d9d9d9"
                strokeWidth="0.2"
                strokeDasharray="0.8, 2"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="60s"
                  from="0 60 60"
                  to="-360 60 60"
                  repeatCount="indefinite"
                />
              </circle>
            </svg>

            <div id="circle-sm"></div>
            <Rocket
              ref={rocketRef}
              className="absolute left-0 z-[2] cursor-pointer"
              onClick={easterEgg}
            />

            <h1 className="title-large text-white absolute left-[62vh]">
              Get in <br /> touch
            </h1>

            <svg width={w} height="100" className="relative z-[1]">
              <line
                x1="0"
                y1="50"
                x2={0}
                y2="50"
                stroke="#d9d9d9"
                strokeWidth="1"
                id="line"
                ref={lineRef}
              />
            </svg>
          </div>

          <div className="md:w-[40vw] flex items-center h-full relative z-[2]">
            <form
              className="w-[350px]"
              id="contactForm"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <h6 className="text-white title-small mb-8">Send me a message</h6>

              <input
                name="name"
                placeholder="Name"
                value={formItems.name}
                onChange={handleChange}
                className="bg-transparent inline-block w-full py-3 outline-none body-base mb-8"
              />

              <input
                name="email"
                placeholder="Email"
                value={formItems.email}
                onChange={handleChange}
                className="bg-transparent inline-block w-full py-3 outline-none body-base mb-8"
              />

              <textarea
                name="message"
                rows={6}
                placeholder="Message"
                value={formItems.message}
                onChange={handleChange}
                className="bg-transparent px-0 inline-block w-full py-3 outline-none body-base mb-12"
              />

              <button
                type="submit"
                className="px-6 py-2 bg-white text-black body-base-strong rounded-md flex items-center hover:text-white"
              >
                {isLoading ? (
                  <Loader className="inline-block mr-2" width="40px" />
                ) : (
                  <Rocket1 className="inline-block mr-2" />
                )}
                Send message
              </button>
            </form>

            <div ref={successRef} className="absolute invisible pl-10">
              <p className="text-white font-semibold mb-8 text-sm">
                Thank you! I'll be in touch soon.
              </p>
              <button
                onClick={onResend}
                className="text-[#BBBBBB] font-semibold mt-8 text-sm hover:opacity-[.2]"
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Contact;
