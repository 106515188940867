import gsap from "gsap";

export function positionActiveArrowDesktop(activePlanet, planetArrowRef) {
  switch (activePlanet?.toLowerCase()) {
    case "earth":
      gsap.to(planetArrowRef.current, {
        // top: "28px",
        y: "20px",
        x: "-20px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "moon":
      gsap.to(planetArrowRef.current, {
        y: "85px",
        x: "-20px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "mars":
      gsap.to(planetArrowRef.current, {
        y: "145px",
        x: "-20px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "asteroid":
      gsap.to(planetArrowRef.current, {
        y: "210px",
        x: "-20px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    default:
      return;
  }
}

export function positionActiveArrowMobile(activePlanet, planetArrowRef,) {
  switch (activePlanet?.toLowerCase()) {
    case "earth":
      gsap.to(planetArrowRef.current, {
        y: "0",
        x: "-120px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "moon":
      gsap.to(planetArrowRef.current, {
        y: "0",
        x: "-45px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "mars":
      gsap.to(planetArrowRef.current, {
        y: "0",
        x: "25px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    case "asteroid":
      gsap.to(planetArrowRef.current, {
        y: "0",
        x: "110px",
        position: "absolute",
        duration: 1,
        ease: "expo",
      });
      break;
    default:
      return;
  }
  
}
