import { createSlice } from "@reduxjs/toolkit";

export const globeSlice = createSlice({
  name: "globe",
  initialState: {
    loaded: false,
    preloaderAnimationComplete: false,
  },
  reducers: {
    setGlobeLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setPreloaderAnimateComplete: (state, action) => {
      state.preloaderAnimationComplete = action.payload;
    },
  },
});

export const { setGlobeLoaded, setPreloaderAnimateComplete } =
  globeSlice.actions;

export default globeSlice.reducer;
