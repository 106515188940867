import React from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { projects } from "../../data/projects";
import {
  setActiveIdx,
  setActivePlanet,
} from "../../redux/projects/projects.slice";
import "./MobileCarousel.css";

const MobileCarousel = ({ containerRef, sliderRef, onProjectClick }) => {
  const dispatch = useDispatch();
  const onActiveCardChange = (index) => {
    const active = projects[index];
    dispatch(setActiveIdx(active.id));
    dispatch(setActivePlanet(active.planet));
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: window.innerWidth < 768 ? 1.003 : 2.35,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    centerMode: true,
    className: "active",
    pauseOnHover: true,
    afterChange: onActiveCardChange,
  };

  return (
    <div
      ref={containerRef}
      id="mobileCarousel"
      className="fixed top-[55vh] sm:top-[70vh] left-0 w-screen h-screen"
    >
      <div className="relative top-0">
        <Slider
          {...sliderSettings}
          ref={(slider) => {
            sliderRef.current = slider;
          }}
        >
          {projects.map((project, idx) => (
            <li
              key={idx}
              className="card w-full h-48 sm:h-56 md:h-64"
              onClick={() => onProjectClick()}
            >
              <img
                id={`img-${idx}`}
                src={project.image}
                key={idx}
                alt="project"
                className="inline-block w-full h-full object-cover m-2"
              />
            </li>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MobileCarousel;
