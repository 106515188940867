import { configureStore } from "@reduxjs/toolkit";
import projectsSlice from "./projects/projects.slice";
import globeSlice from "./globe/globe.slice";

export default configureStore({
  reducer: {
    projects: projectsSlice,
    globe: globeSlice,
  },
});
